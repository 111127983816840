import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react'
import { notify } from 'react-notify-toast';
import callApi from '../../../../util/apiCaller';
import * as yup from 'yup';


const MODEL_NAME = `fooditemingredients`; // for api call
const MODEL ='food_item_ingredients' //for response
const add = "Added"
const server = "Something went wrong"
const update = "Updated"
const deleted = "Deleted"
const refresh = "Refreshed"

export class FoodItemIngredients extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            initialValues: {
                serving_number: "",
                preparation_quantity: "",
                ingredient_quantity: "",
                calorie: "",
                protein: "",
                fats: "",
                iron: "",
                calcium: "",
                carbohydrates: "",
                fibre: "",
                sodium: "",
                measurement: "",
                status: "",
                remarks: ""
            },
            ingredientsId: "",
            isValid: false,
            isLoaing: false
        }
    }
    

    componentDidMount(){
        if(this.props.data && this.props.data._id){
            let item = this.props.data;
            let { initialValues, ingredientsId } = this.state;
            initialValues = {
                serving_number: item.serving_number,
                preparation_quantity: item.preparation_quantity,
                ingredient_quantity: item.ingredient_quantity,
                calorie: item.calorie,
                protein: item.protein,
                fats: item.fats,
                iron: item.iron,
                calcium: item.calcium,
                carbohydrates: item.carbohydrates,
                fibre: item.fats,
                sodium: item.sodium,
                measurement: item.cooked_quantity,
                status: item.status,
                remarks: item.remarks
            }
            ingredientsId = item._id
            this.setState({ 
                initialValues, ingredientsId
            }, () => console.log(this.state.initialValues))
        }
    }

    // getItem = (id) => {
    //     const body = {
    //         query: {}
    //     }
    //     body.query['food_item_id'] = id;
    //     callApi(`v2/${MODEL_NAME}/list`, 'POST', body).then(res => {
    //         if (res && res.status === 'Success') {
    //             console.log("ingredients", res.data)
    //             if(res.data && res.data.count === 1){
    //                 let item = res.data.food_item_ingredientss[0]
    //                 let { initialValues, ingredientsId } = this.state;
    //                 initialValues = {
    //                     serving_number: item.serving_number,
    //                     preparation_quantity: item.preparation_quantity,
    //                     ingredient_quantity: item.ingredient_quantity,
    //                     calorie: item.calorie,
    //                     protein: item.protein,
    //                     fats: item.fats,
    //                     iron: item.iron,
    //                     calcium: item.calcium,
    //                     carbohydrates: item.carbohydrates,
    //                     fibre: item.fats,
    //                     sodium: item.sodium,
    //                     measurement: item.cooked_quantity,
    //                     status: item.status,
    //                     remarks: item.remarks
    //                 }
    //                 ingredientsId = item._id
    //                 this.setState({ 
    //                     initialValues, ingredientsId
    //                  }, () => console.log(this.state.initialValues))
    //             }
    //         }else{
    //             this.showMessage(`${server}`, "error")
    //         }
    //     }).catch(err => {
    //         this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`, "error");
    //     });
    // };



    MySchema = yup.object().shape({
        serving_number: yup.string().trim()
        .required('Serving Number is required'),
        preparation_quantity: yup.string().trim()
        .required('Preparation Quantity is required'),
        ingredient_quantity: yup.string().trim()
        .required('Ingredient Quantity is required'),
        calorie: yup.string().trim()
          .required('Calorie is required'),
        protein: yup.string().trim()
          .required('Protein is required'),
        fats: yup.string().trim()
          .required('Fat is required'),
        iron: yup.string().trim()
          .required('Iron is required'),
        calcium: yup.string().trim()
          .required('Calcium is required'),
        carbohydrates: yup.string().trim()
          .required('Carbohydrates is required'),
        fibre: yup.string().trim()
          .required('Fibre is required'),
        sodium: yup.string().trim()
          .required('Sodium is required'),
        cooked_quantity: yup.string().trim()
          .required('Cooked Quantity is required'),
        status: yup.string().trim()
          .required('Status is required'),
        remarks: yup.string().trim()
          .required('Remarks is required'),
    });

    


    onSubmit = (values, onSubmitProps) => {
        console.log(values)
        if(this.props.foodItem._id && this.props.data._id){
            this.updateIngredients(this.props.data._id, values)
        }else{
            this.addIngredients({...values, food_item_id: this.props.foodItem._id});
            onSubmitProps.resetForm()
        }
    }

    addIngredients = item => {
        let body = {}
        body[MODEL] = item;
        callApi(`v2/${MODEL_NAME}/new`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                let newItem = res.data[MODEL];
                // this.setState({initialValues: newItem}, () => console.log(this.state.initialValues));
                this.showMessage(`${add}`, "success");
            }else{
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            console.error(err);
            this.showMessage(`Error on adding ${MODEL_NAME.toUpperCase()}`, "error");
        });
    }

    updateIngredients = (id, item) => {
        let body = {}
        body[MODEL] = item;
        callApi(`v2/${MODEL_NAME}/${id}/update`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                let newItem = res.data[MODEL];
                this.showMessage(`${update}`, "success");
            }else{
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`, "error");
        });
    }

    
    showMessage = (msg, msgType) => {
        if(msgType === "success"){
            notify.show(msg, msgType, 3000);
        }
        if(msgType === "error"){
            notify.show(msg, msgType, 3000);
        }
    };

    render() {
        return (
            <div>
                <h2>Food Item Ingredients</h2>
                <Formik
                        initialValues={{
                            serving_number: this.props.data.serving_number ?? "",
                            preparation_quantity: this.props.data.preparation_quantity ?? "",
                            ingredient_quantity: this.props.data.ingredient_quantity ?? "",
                            calorie: this.props.data.calorie ?? "",
                            protein: this.props.data.protein ?? "",
                            fats: this.props.data.fats ?? "",
                            iron: this.props.data.iron ?? "",
                            calcium: this.props.data.calcium ?? "",
                            carbohydrates: this.props.data.carbohydrates ?? "",
                            fibre: this.props.data.fats ?? "",
                            sodium: this.props.data.sodium ?? "",
                            measurement: this.props.data.cooked_quantity ?? "",
                            status: this.props.data.status ?? "",
                            remarks: this.props.data.remarks ?? ""
                        }}
                        validationSchema={this.MySchema}
                        onSubmit={this.onSubmit}
                >
                    {({values, handleChange, touched, errors, isSubmitting, ...props}) => (
                        <Form>
                            <div className="row">
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="serving_number">Serving Number</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.serving_number && errors.serving_number ? "is-invalid" : ""
                                    }`}
                                    id="serving_number" 
                                    name="serving_number" 
                                    value={values.serving_number} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="serving_number"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="calorieN">Preparation Quantity</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.preparation_quantity && errors.preparation_quantity ? "is-invalid" : ""
                                    }`}
                                    id="preparation_quantity" 
                                    name="preparation_quantity" 
                                    value={values.preparation_quantity} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="preparation_quantity"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="ingredient_quantity">Ingredient Quantity</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.ingredient_quantity && errors.ingredient_quantity ? "is-invalid" : ""
                                    }`}
                                    id="ingredient_quantity" 
                                    name="ingredient_quantity" 
                                    value={values.ingredient_quantity} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="ingredient_quantity"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="calorieN">Calorie</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.calorie && errors.calorie ? "is-invalid" : ""
                                    }`}
                                    id="calorieN" 
                                    name="calorie" 
                                    value={values.calorie} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="calorie"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="proteinN">Protein</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.protein && errors.protein ? "is-invalid" : ""
                                    }`}
                                    id="proteinN" 
                                    name="protein" 
                                    value={values.protein} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="protein"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="fatN">Fat</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.fats && errors.fats ? "is-invalid" : ""
                                    }`}
                                    id="fatN" 
                                    name="fats" 
                                    value={values.fats} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="fats"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="ironN">Iron</label>
                                    <Field type="text" 
                                    className={`form-control ${
                                        touched.iron && errors.iron ? "is-invalid" : ""
                                    }`}
                                    id="ironN" 
                                    name="iron" 
                                    value={values.iron} 
                                    onChange={handleChange} required />
                                    <ErrorMessage
                                    component="div"
                                    name="iron"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="calciumN">Calcium</label>
                                    <Field type="text" 
                                    className={`form-control ${
                                        touched.calcium && errors.calcium ? "is-invalid" : ""
                                    }`}
                                    id="calciumN" 
                                    name="calcium" 
                                    value={values.calcium} 
                                    onChange={handleChange} required />
                                    <ErrorMessage
                                    component="div"
                                    name="calcium"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="carbohydratesN">Carbohydrates</label>
                                    <Field type="text" 
                                    className={`form-control ${
                                        touched.carbohydrates && errors.carbohydrates ? "is-invalid" : ""
                                    }`} 
                                    id="carbohydratesN" 
                                    name="carbohydrates" 
                                    value={values.carbohydrates} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="carbohydrates"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="fibreN">Fibre</label>
                                    <Field type="text" 
                                    className={`form-control ${
                                        touched.fibre && errors.fibre ? "is-invalid" : ""
                                    }`} 
                                    id="fibreN" 
                                    name="fibre" 
                                    value={values.fibre} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="fibre"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="sodiumN">Sodium</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.sodium && errors.sodium ? "is-invalid" : ""
                                    }`} 
                                    id="sodiumN" 
                                    name="sodium" 
                                    value={values.sodium} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="sodium"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="cooked-quantity">Measurement</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.measurement && errors.measurement ? "is-invalid" : ""
                                    }`} 
                                    id="cooked-quantity" 
                                    name="measurement" 
                                    value={values.measurement} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="measurement"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="statusN">Status</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.status && errors.status ? "is-invalid" : ""
                                    }`} 
                                    id="statusN" 
                                    name="status" 
                                    value={values.status} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="status"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="remarksN">Remarks</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.remarks && errors.remarks ? "is-invalid" : ""
                                    }`} 
                                    id="remarksN" 
                                    name="remarks" 
                                    value={values.remarks} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="remarks"
                                    className="invalid-feedback"
                                    />
                                </div>
                            </div>
                            <div className="my-3">
                                <button className="btn btn-primary" type="submit">
                                    {this.props.data._id ? "Update" : "Add"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default FoodItemIngredients
