import React from 'react';

function ListTableHeader(props) {
    return (
        <tr style={{fontSize:"15px"}}>
            <th style={{minWidth: "150px"}}>FoodItemID</th>
            <th style={{minWidth: "120px"}}>Serving_number</th>
            <th style={{minWidth: "120px"}}>Prepration_quantity</th>
            <th style={{minWidth: "120px"}}>Ingredient_quantity</th>
            <th style={{minWidth: "120px"}}>calorie</th>
            <th style={{minWidth: "120px"}}>protein</th>
            <th style={{minWidth: "120px"}}>fats</th>
            <th style={{minWidth: "120px"}}>iron</th>
            <th style={{minWidth: "120px"}}>calcium</th>
            <th style={{minWidth: "120px"}}>carbohydrate</th>
            <th style={{minWidth: "120px"}}>fibre</th>
            <th style={{minWidth: "120px"}}>mesurement</th>
            <th style={{minWidth: "120px"}}>Sodium</th>
            <th style={{minWidth: "120px"}}>remark</th>
            <th style={{minWidth: "150px"}}>Action</th>
        </tr>
    );
}

export default ListTableHeader;


