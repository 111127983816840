import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react'
import { notify } from 'react-notify-toast';
import callApi from '../../../../util/apiCaller';
import * as yup from 'yup';


const MODEL_NAME = `fooditemallergiesinfo`; // for api call
const MODEL ='food_item_allergies_info' //for response
const add = "Added"
const server = "Something went wrong"
const update = "Updated"
const deleted = "Deleted"
const refresh = "Refreshed"

export class FoodItemAllergies extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            initialValues: {
                status: "",
                remarks: ""
            },
            allergiesId: "",
            isValid: false,
            isLoaing: false
        }
    }
    

    componentDidMount(){
        if(this.props.data && this.props.data._id){
            let item = this.props.data;
            let { initialValues, allergiesId } = this.state;
            initialValues = {
                status: item.status,
                remarks: item.remarks
            }
            allergiesId = item._id
            this.setState({ 
                initialValues, allergiesId
            }, () => console.log(this.state.initialValues))
        }
    }

    // getItem = (id) => {
    //     const body = {
    //         query: {}
    //     }
    //     body.query['food_item_id'] = id;
    //     callApi(`v2/${MODEL_NAME}/list`, 'POST', body).then(res => {
    //         if (res && res.status === 'Success') {
    //             console.log("allergies", res.data)
    //             if(res.data && res.data.count === 1){
    //                 let item = res.data.food_item_allergies_infos[0]
    //                 let { initialValues, allergiesId } = this.state;
    //                 initialValues = {
    //                     status: item.status,
    //                     remarks: item.remarks
    //                 }
    //                 allergiesId = item._id
    //                 this.setState({ 
    //                     initialValues, allergiesId
    //                  }, () => console.log(this.state.initialValues))
    //             }
    //         }else{
    //             this.showMessage(`${server}`, "error")
    //         }
    //     }).catch(err => {
    //         this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`, "error");
    //     });
    // };



    MySchema = yup.object().shape({
        status: yup.string().trim()
          .required('Status is required'),
        remarks: yup.string().trim()
          .required('Remarks is required'),
    });

    


    onSubmit = (values, onSubmitProps) => {
        console.log(values)
        if(this.props.foodItem._id && this.props.data._id){
            this.updateAllergies(this.props.data._id, values)
        }else{
            this.addAllergies({...values, food_item_id: this.props.foodItem._id});
            onSubmitProps.resetForm()
        }
    }

    addAllergies = item => {
        let body = {}
        body[MODEL] = item;
        callApi(`v2/${MODEL_NAME}/new`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                let newItem = res.data[MODEL];
                this.setState({initialValues: newItem}, () => console.log(this.state.initialValues));
                this.showMessage(`${add}`, "success");
            }else{
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            console.error(err);
            this.showMessage(`Error on adding ${MODEL_NAME.toUpperCase()}`, "error");
        });
    }

    updateAllergies = (id, item) => {
        let body = {}
        body[MODEL] = item;
        callApi(`v2/${MODEL_NAME}/${id}/update`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                let newItem = res.data[MODEL];
                this.showMessage(`${update}`, "success");
            }else{
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`, "error");
        });
    }

    
    showMessage = (msg, msgType) => {
        if(msgType === "success"){
            notify.show(msg, msgType, 3000);
        }
        if(msgType === "error"){
            notify.show(msg, msgType, 3000);
        }
    };

    render() {
        return (
            <div>
                <h2>Food Item Ingredients</h2>
                <Formik
                        initialValues={{
                            status: this.props.data.status ?? "",
                            remarks: this.props.data.remarks ?? ""
                        }}
                        validationSchema={this.MySchema}
                        onSubmit={this.onSubmit}
                >
                    {({values, handleChange, touched, errors, isSubmitting, ...props}) => (
                        <Form>
                            <div className="row">
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="statusN">Status</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.status && errors.status ? "is-invalid" : ""
                                    }`} 
                                    id="statusN" 
                                    name="status" 
                                    value={values.status} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="status"
                                    className="invalid-feedback"
                                    />
                                </div>
                                <div className="col-lg-5 col-md-6 col-12">
                                    <label htmlFor="remarksN">Remarks</label>
                                    <Field 
                                    type="text" 
                                    className={`form-control ${
                                        touched.remarks && errors.remarks ? "is-invalid" : ""
                                    }`} 
                                    id="remarksN" 
                                    name="remarks" 
                                    value={values.remarks} 
                                    onChange={handleChange} 
                                    required />
                                    <ErrorMessage
                                    component="div"
                                    name="remarks"
                                    className="invalid-feedback"
                                    />
                                </div>
                            </div>
                            <div className="my-3">
                                <button className="btn btn-primary" type="submit">
                                    {this.props.data._id ? "Update" : "Add"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default FoodItemAllergies
