import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            scheduled_date:"",
            combo:[],
            meal_type:"",
            thumbnail_img:"",
            big_img:"",
            isActive:false,
            remarks:"",
            status:"",
            scheduleddateerror:"",
            comboerror:"",
            maeltypeerror:"",
            thumbnailimgerror:"",
            bigimgerror:"",
            remarkserror:"",
            statuserror:""
           
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                scheduled_date: this.props.user.scheduled_date,
                combo: this.props.user.combo,
                meal_type: this.props.user.meal_type,
                thumbnail_img: this.props.user.thumbnail_img,
                big_img: this.props.user.big_img,
                remarks: this.props.user.remarks,
                status: this.props.user.status,
                isActive: this.state.isActive

            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { scheduled_date, combo, meal_type, thumbnail_img, big_img, status, remarks } = this.state
        if(scheduled_date.length < 1){
            error["scheduled_date"] = "scheduled_date Number is required"
        }else if(!validator.isDate(scheduled_date)){
            error["scheduled_date"] = "Please enter valid date"
        }
        if(combo.length < 1){
            error["combo"] = "combo is required"
        }
        if(meal_type.length < 1){
            error["meal_type"] = "meal_type is required"
        }
        if(thumbnail_img.length < 1){
            error["thumbnail_img"] = "thumbnail_img is required"
        }
        if(big_img.length < 1){
            error["big_img"] = "big_img is required"
        }
        if(remarks.length < 1){
            error["remarks"] = "remarks is required"
        }
    
        if(status.length < 1){
            error["status"] = "status  is required"
        }
        if(error["scheduled_date"] || error["remarks"] || error["meal_type"] || error["combo"] || error["big_img"] || error["thumbnail_img"]  ){
            this.setState({
                scheduleddateerror: error["scheduled_date"],
                comboerror: error["combo"],
                maeltypeerror: error["meal_type"],
                thumbnailimgerror: error["thumbnail_img"],
                bigimgerror: error["big_img"],
                remarkserror: error["remarks"],

                
            })
        }else{
            let temp = meal_type.trim().split(" ")
            let tempName = []
            for(let i=0; i < temp.length; i++){
                if(temp[i] !== ''){
                    tempName.push(temp[i])
                }
            }
            let name = tempName.join(" ")
            let body = {
                scheduled_date: scheduled_date,
                combo: combo,
                meal_type: meal_type,
                thumbnail_img: thumbnail_img,
                big_img: big_img,
                remarks: remarks,
                isActive: this.state.isActive
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    scheduled_date:"",
                    combo:[],
                    meal_type:"",
                    thumbnail_img:"",
                    big_img:"",
                    remarks:"",
                    updated_by:"",
                    created_on:"",
                    changed_on:"",
                    isActive:""
                })
            }        
        }
    }
    
    render() {
        const { isPublished } = this.state;
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                            
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="scheduled_date">Enter scheduled_date</label>
                                <input type="date" value={this.state.scheduled_date} onChange={(e) => this.setState({ scheduled_date: e.target.value, scheduleddateerror:e.target.value.length > 0? null: this.state.scheduleddateerror })} />
                                {this.state.scheduleddateerror ? <small style={{color: "red"}}>*{this.state.scheduleddateerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="combo">Enter combo</label>
                                <input type="text" value={this.state.combo} onChange={(e) => this.setState({ combo: e.target.value, comboerror:e.target.value.length > 0? null: this.state.comboerror })} />
                                {this.state.comboerror ? <small style={{color: "red"}}>*{this.state.comboerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="meal_type">Enter meal_type</label>
                                <input type="text" value={this.state.meal_type} onChange={(e) => this.setState({ meal_type: e.target.value, maeltypeerror:e.target.value.length > 0? null: this.state.maeltypeerror })} />
                                {this.state.maeltypeerror ? <small style={{color: "red"}}>*{this.state.maeltypeerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="thumbnail_img">Enter thumbnail_img</label>
                                <input type="text" value={this.state.thumbnail_img} onChange={(e) => this.setState({ thumbnail_img: e.target.value, thumbnailimgerror:e.target.value.length > 0? null: this.state.thumbnailimgerror })} />
                                {this.state.thumbnailimgerror ? <small style={{color: "red"}}>*{this.state.thumbnailimgerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="big_img">Enter big_img</label>
                                <input type="text" value={this.state.big_img} onChange={(e) => this.setState({ big_img: e.target.value, bigimgerror:e.target.value.length > 0? null: this.state.bigimgerror })} />
                                {this.state.bigimgerror ? <small style={{color: "red"}}>*{this.state.bigimgerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input type="text" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value, remarkserror:e.target.value.length > 0? null: this.state.remarkserror })} />
                                {this.state.remarkserror ? <small style={{color: "red"}}>*{this.state.remarkserror}</small> : null}
                            </div>
                            <div className="input_box">
                               <label htmlFor="isActive">isActive</label>
                               <select
                                        onChange={(e) => this.setState({ isActive: e.target.value })}
                                        className="form-control" id="isActive"
                                        value={this.state.isActive}>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                           </div>
                            
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
