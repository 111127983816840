import React, { Component } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import VisibilityIcon from "@material-ui/icons/Visibility"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {
        const { ingredient,
            alias1,
            alias2,
            alias3,
            calorie,
            protein,
            fats,
            iron,
            calcium,
            carbohydrates,
            fibre,
            sodium,
            measurement,
            nutrition_id,
            update_by,
            remarks, status } = this.props.user
        return (
            <tr className="">
                <td style={{ minWidth: "150px", fontWeight: "500" }}>{ingredient}</td>
                <td style={{ minWidth: "120px" }}>
                    <a>{alias1}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{alias2}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{alias3}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{calorie}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{protein}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{fats}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{iron}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{calcium}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{carbohydrates}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{fibre}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{sodium}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{measurement}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{nutrition_id}</a>
                </td>
                <td style={{ minWidth: "120px" }}>
                    <a>{update_by}</a>
                </td>
                <td style={{ minWidth: "180px" }}>
                    <a>{remarks}</a>
                </td>
                <td style={{ minWidth: "180px" }}>
                    <a>{status}</a>
                </td>
                <td style={{ minWidth: "80px" }}>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Edit
                        </Tooltip>
                        }
                    >
                        <EditIcon onClick={() => this.props.displayModal(this.props.user._id)} fontSize="small" style={{ color: "#3399ff", marginRight: "15px", cursor: "pointer" }} />
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Delete
                        </Tooltip>
                        }
                    >
                        <DeleteIcon onClick={() => this.props.deleteUser(this.props.user._id)} fontSize="small" style={{ color: "#d81212", cursor: "pointer" }} />
                    </OverlayTrigger>
                </td>

            </tr>
        );
    }
}

export default ListTableRow;


