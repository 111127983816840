import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             username: "",
             email: "",
             phone: "",
            emailerror: "",
            nameerror: "",
            phoneerror: ""
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                username: this.props.user.local_username,
                email: this.props.user.local_email,
                phone: this.props.user.phone,
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { username, email, phone } = this.state
        if(email.length < 1){
            error["email"] = "Email is required"
        }else if(!isEmailValid(email)){
            error["email"] = "Email is not Valid"
        }
        if(phone.length < 1){
            error["phone"] = "Phone Number is required"
        }else if(!isPhoneValid(phone)){
            error["phone"] = "Phone Number is not Valid"
        }
        if(username.length < 1){
            error["name"] = "Name is required"
        }else if(!isNameValid(username)){
            error["name"] = "Name only contain letters and Space"
        }
        if(error["email"] || error["phone"] || error["name"]){
            this.setState({
                emailerror: error["email"],
                phoneerror: error["phone"],
                nameerror: error["name"]
            })
        }else{
            let temp = username.trim().split(" ")
            let tempName = []
            for(let i=0; i < temp.length; i++){
                if(temp[i] !== ''){
                    tempName.push(temp[i])
                }
            }
            let name = tempName.join(" ")
            let body = {
                local_username: name,
                local_email: email,
                phone: phone
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    username: "",
                    email: "",
                    phone: "",
                })
            }        
        }
    }
    
    render() {
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="name">Enter Name</label>
                                <input 
                                    type="text" 
                                    value={this.state.username} 
                                    onChange={(e) => this.setState({ username: e.target.value, nameerror:e.target.value.length > 0? null: this.state.nameerror })} />
                                {this.state.nameerror ? <small style={{color: "red"}}>*{this.state.nameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="email">Enter Email</label>
                                <input 
                                    type="email" 
                                    value={this.state.email} 
                                    onChange={(e) => this.setState({ email: e.target.value, emailerror:e.target.value.length > 0? null: this.state.emailerror })} />
                                {this.state.emailerror ? <small style={{color: "red"}}>*{this.state.emailerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="phone">Enter Phone Number</label>
                                <input 
                                    type="text" 
                                    value={this.state.phone} 
                                    onChange={(e) => this.setState({ phone: e.target.value, phoneerror:e.target.value.length > 0? null: this.state.phoneerror })} />
                                {this.state.phoneerror ? <small style={{color: "red"}}>*{this.state.phoneerror}</small> : null}
                            </div>
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
