import React from 'react';
import callApi from '../../../util/apiCaller';
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import '../LoginPage/login.css'


class SignupPage extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            email:'',
            phone:'',
            submitted: false,
            loading: false,
            error: '',
            erroruser: '',
            erroremail: '',
            errorphone: '',
            errorpassword: '',
            
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleResponse = (response) => {
        console.log("response", response)
        if(response.status){
            console.log("response", response)
            return response;
        }else{
            return null;
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    signup = (email, password, name, phone)=> {
        console.log("service");
        return callApi(`v1/auth/signup`, 'post', {local_email:email, local_password:password, local_username:name, phone:phone, isAdmin:true})
            .then(this.handleResponse)
            .then(admin =>{
                console.log(admin);
                return admin;
            })
            .catch(err => {
                console.log(err);
               this.setState({ err, loading: false });
            });
    
        }

   

    handleSubmit = (e) => {
        e.preventDefault();


        this.setState({ 
            submitted: true,
            erroruser: '',
            erroremail: '',
            errorphone: '',
            errorpassword: '',
         });

        let errors = {}
        const { username, password, email, phone } = this.state;
        const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!username) {
            errors["name"]  = "Name is required";
        }else if(username.length < 2){
            errors["name"]  = "Name length must be greater than 2";
        }else if(!username.match(/^[a-zA-Z\s]*$/)){
            errors["name"] = "Name only contains letters and space"
        }

        if (!email) {
            errors["email"]  = "Email is required";
        }else if(!email.match(mailformat)){
            errors["email"]  = "Email is not Valid";
        }
        
        if (!phone) {
            errors["phone"]  = "Phone Number is required";
        }else if(phone.length !== 10){
            errors["phone"]  = "Phone Number length is not valid";
        }

        if (!password) {
            errors["password"]  =  "Password is required";
        }else if(password.length < 8 || password.length > 32){
            errors["password"]  =  "Password lenght should not be less than 8 and greater than 32";
        }



        if(errors["name"] || errors["email"] || errors["phone"] || errors["password"]){
            this.setState({
                erroruser: errors["name"],
                erroremail: errors["email"],
                errorphone: errors["phone"],
                errorpassword: errors["password"]
            })
            return;
        }

        this.setState({ loading: true }, () => {
            
        });
        console.log("submit");
        this.signup(email, password, username, phone)
            .then(admin => {
                if(admin){
                    alert("Account is successfully Created (:")
                    this.props.history.push("/login")
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    console.log(admin)
                }else{
                    alert("Signup is failed")
                    console.log("admin", admin);
                    this.setState({ loading: false });
                }
                    // alert("Account is successfully Created (:")
                    // this.props.history.push("/login")
                    // const { from } = this.props.location.state || { from: { pathname: "/" } };
                    // this.props.history.push(from);
            })
            .catch(error => {
               console.log(error);
               this.setState({ error, loading: false });
            });
    } 


    render(){
        const { username, password, email, loading, error, phone,submitted, erroremail, erroruser, errorpassword, errorphone } = this.state;
        return(
            <div className="container-fluid">
            <div className="row no-gutters">
            <div className="col-lg-5">
        <div className="authentication-bg" style={{background: "#292626",width: "100%",height: "100vh"}}>
        <img src="https://cdn.zillskill.com/wordpress/2020/12/15135400/Brain_720x.png" style={{width:"100%",objectFit:"cover",height:"100vh"}}/>
        </div>
         </div>
            <div className="col-md-6 col-lg-6 sigun_form">
            <h2>SignUp</h2>
            <form name="form" onSubmit={this.handleSubmit} style={{padding:"0px 30px"}}>
                <div className={'form-group' + (submitted && !username ? ' has-error' : '') }>
                    <label htmlFor="username">Name</label>
                    <input type="text" className="form-control input_login" name="username" value={username} placeholder="name" onChange={this.handleChange} />
                    {(submitted && erroruser) &&
                        <div className="help-block" style={{color:"red"}}>*{`${erroruser}`}</div>
                    }
                </div>
                <div className={'form-group'+ (submitted && !email ? ' has-error' : '') }>
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control input_login" name="email" value={email} placeholder="Email" onChange={this.handleChange} />
                    {(submitted && erroremail) && 
                        <div className="help-block" style={{color:"red"}}>*{`${erroremail}`}</div>
                    }
                </div>
                <div className={'form-group' + (submitted && !phone ? ' has-error' : '')}>
                    <label htmlFor="phone">Phone</label>
                    <input type="text" className="form-control input_login" name="phone" value={phone} placeholder="Phone" onChange={this.handleChange} />
                    {(submitted && errorphone) &&
                        <div className="help-block" style={{color:"red"}}>*{`${errorphone}`}</div>
                    }
                </div>
                <div className={'form-group' + (submitted && !password ? ' has-error' : '') }>
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control input_login" name="password" value={password} placeholder="Password" onChange={this.handleChange} />
                    {(submitted && errorpassword) &&
                        <div className="help-block" style={{color:"red"}}>*{`${errorpassword}`}</div>
                    }
                </div>
                <div className="form-group">
                    <button className="btn btn-primary"
                     onClick={(e) => this.handleSubmit(e)}
                    disabled={loading}>SignUp</button>
                    <Button style={{margin:"10px"}}
                     onClick= {() =>  this.props.history.push("/login")}
                     id="account-btn">Already have an account..!
                    </Button>
                    {loading &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loading" />
                    }
                </div>
                {error &&
                    <div className={'alert alert-danger'}>{error}</div>
                }
            </form>
        </div>
        

</div>
</div>
        );
    }

   
}

export default withRouter(SignupPage);