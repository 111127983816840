import React from 'react';

function ListTableHeader(props) {
    return (
        <tr style={{ fontSize: "15px" }}>
            <th style={{ minWidth: "150px" }}>Ingredient</th>
            <th style={{ minWidth: "150px" }}>Alias1</th>
            <th style={{ minWidth: "150px" }}>Alias 2</th>
            <th style={{ minWidth: "150px" }}>Alias 3</th>
            <th style={{ minWidth: "150px" }}>Calories</th>
            <th style={{ minWidth: "150px" }}>protien</th>
            <th style={{ minWidth: "150px" }}>fats</th>
            <th style={{ minWidth: "150px" }}>iron</th>
            <th style={{ minWidth: "150px" }}>calcium</th>
            <th style={{ minWidth: "150px" }}>carbohydrates</th>
            <th style={{ minWidth: "150px" }}>fibre</th>
            <th style={{ minWidth: "150px" }}>sodium</th>
            <th style={{ minWidth: "150px" }}>measurement</th>
            <th style={{ minWidth: "150px" }}>nutrition_id</th>
            <th style={{ minWidth: "150px" }}>updated_by</th>
            <th style={{ minWidth: "150px" }}>Remarks</th>
            <th style={{ minWidth: "150px" }}>Status</th>
            <th style={{ minWidth: "150px" }}>Action</th>
        </tr>
    );
}

export default ListTableHeader;


