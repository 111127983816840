import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            foodItemID:"",
            orderType:"",
            quantity:"",
            measurement:"",
            remarks:"",
            isActive:false,
            ordertypeerror:"",
            quantityerror:"",
            measurementerror:"",
            remarkerror:"",
            isPublished:null

        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                orderType: this.props.user.orderType,
                quantity: this.props.user.quantity,
                measurement: this.props.user.measurement,
                remarks: this.props.user.remarks,
                isActive: this.props.user.isActive

            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { orderType, quantity, measurement, remarks} = this.state
        if(orderType.length < 1){
            error["orderType"] = "orderType is required"
        }
        if(quantity.length < 1){
            error["quantity"] = "quantity is required"
        }
        if(measurement.length < 1){
            error["measurement"] = "measurement is required"
        }
        if(remarks.length < 1){
            error["remark"] = "remark is required"
        }
       
        if(error["orderType"] || error["quantity"] || error["measurement"] || error["remark"] ){
            this.setState({
                ordertypeerror: error["orderType"],
                quantityerror: error["quantity"],
                measurementerror: error["measurement"],
                remarkerror: error["remark"],
               
            })
        }else{
            let body = {
                orderType: orderType,
                quantity: quantity,
                measurement: measurement,
                remarks: remarks,
                isActive: this.state.isActive,
                isDeleted: false
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    orderType:"",
                    quantity:"",
                    measurement:"",
                    remarks:"",
                    isActive:""
                })
            }        
        }
    }
    
    render() {
       
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="orderType">Enter orderType</label>
                                <input 
                                    type="text" 
                                    value={this.state.orderType} 
                                    onChange={(e) => this.setState({ orderType: e.target.value, ordertypeerror:e.target.value.length > 0? null: this.state.ordertypeerror })} />
                                {this.state.ordertypeerror ? <small style={{color: "red"}}>*{this.state.ordertypeerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="quantity">Enter quantity</label>
                                <input 
                                    type="number" 
                                    value={this.state.quantity} 
                                    onChange={(e) => this.setState({ quantity: e.target.value, quantityerror:e.target.value.length > 0? null: this.state.quantityerror })} />
                                {this.state.quantityerror ? <small style={{color: "red"}}>*{this.state.quantityerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="measurement">Enter measurement</label>
                                <input 
                                    type="text" 
                                    value={this.state.measurement} 
                                    onChange={(e) => this.setState({ measurement: e.target.value, measurementerror:e.target.value.length > 0? null: this.state.measurementerror })} />
                                {this.state.measurementerror ? <small style={{color: "red"}}>*{this.state.measurementerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input 
                                    type="text" 
                                    value={this.state.remarks} 
                                    onChange={(e) => this.setState({ remarks: e.target.value, remarkerror:e.target.value.length > 0? null: this.state.remarkerror })} />
                                {this.state.remarkerror ? <small style={{color: "red"}}>*{this.state.remarkerror}</small> : null}
                            </div>
                            <div className="input_box">
                                    <label htmlFor="isActive" className="form-label">isActive</label>
                                    <select
                                        onChange={(e) => this.setState({ isActive: e.target.value })}
                                        className="form-control" id="isActive"
                                        value={this.state.isActive}>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </div>
                            
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
