import React from 'react';

function ListTableHeader(props) {
    return (
        <tr style={{fontSize:"15px"}}>
            <th style={{minWidth: "150px"}}>Name</th>
            <th style={{minWidth: "150px"}}>food_category</th>
            <th style={{minWidth: "150px"}}>display_name</th>
            <th style={{minWidth: "150px"}}>cuisine_type</th>
            <th style={{minWidth: "150px"}}>food_item_variant</th>
            <th style={{minWidth: "150px"}}>Portion_type</th>
            <th style={{minWidth: "120px"}}>isBreakfast</th>
            <th style={{minWidth: "120px"}}>isSnack</th>
            <th style={{minWidth: "120px"}}>isDinner</th>
            <th style={{minWidth: "150px"}}>thumbnail_image</th>
            <th style={{minWidth: "150px"}}>big_img</th>
            <th style={{minWidth: "150px"}}>slideimage_1</th>
            <th style={{minWidth: "150px"}}>video</th>
            <th style={{minWidth: "150px"}}>slideimage_2</th>
            <th style={{minWidth: "150px"}}>slideimage_3</th>
            <th style={{minWidth: "150px"}}>video_thumbnail</th>
            <th style={{minWidth: "150px"}}>isApproved</th>
            <th style={{minWidth: "150px"}}>Status</th>
            <th style={{minWidth: "120px"}}>remark</th>
            <th style={{minWidth: "150px"}}>Action</th>
        </tr>
    );
}

export default ListTableHeader;


