import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            name:"",
            color:"",
            remarks:"",
            updated_by:"",
            created_on:"",
            changed_on:"",
            isActive:false,
            nameerror:"",
            colorerror:"",
            remarkserror:"",
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                name: this.props.user.name,
               color: this.props.user.color,
                remarks: this.props.user.remarks,
                isActive: this.props.user.isActive

            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { name, color, remarks } = this.state
        if(name.length < 1){
            error["name"] = "name is required"
        }else if(!isNameValid(name)){
            error["name"] = "Name only contain letters and Space"
        }
        if(color.length < 1){
            error["color"] = "Color is required"
        }
        if(remarks.length < 1){
            error["remarks"] = "remarks is required"
        }
        if(error["name"] || error["color"] || error["remarks"] ){
            this.setState({
                nameerror: error["name"],
                colorerror: error["color"],
                remarkserror: error["remarks"],
            })
        }else{
            let body = {
                name: name,
                color: color,
                remarks: remarks,
                isActive: this.state.isActive,
                isDeleted: false
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    name:"",
                    remarks:"",
                    color:"",
                    isActive:""
                })
            }        
        }
    }
    
    render() {
        
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="name">Enter name</label>
                                <input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value, nameerror:e.target.value.length > 0? null: this.state.nameerror })} />
                                {this.state.nameerror ? <small style={{color: "red"}}>*{this.state.nameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="color">Enter color</label>
                                <input type="text" value={this.state.color} onChange={(e) => this.setState({ color: e.target.value, colorerror:e.target.value.length > 0? null: this.state.colorerror })} />
                                {this.state.colorerror ? <small style={{color: "red"}}>*{this.state.colorerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input type="text" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value, remarkserror:e.target.value.length > 0? null: this.state.remarkserror })} />
                                {this.state.remarkserror ? <small style={{color: "red"}}>*{this.state.remarkserror}</small> : null}
                            </div>
                            <div className="input_box">
                                    <label htmlFor="isActive" className="form-label">isActive</label>
                                    <select
                                        onChange={(e) => this.setState({ isActive: e.target.value })}
                                        className="form-control" id="isActive"
                                        value={this.state.isActive}>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </div>
                            
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
