const API_URL = process.env.REACT_APP_API_URL || "http://mb-api.dev.venuemonk.com";

export default function callApi(endpoint, method = 'get', body) {
	let token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : '';
	let headers = {};
	headers['content-type'] = 'application/json';
	if (token && token !== '') {
		headers.Authorization = `Bearer ${token}`;
	}
	console.log(`${API_URL} / ${endpoint}`)
	return fetch(`${API_URL}/${endpoint}`, {
		headers: headers,
		method,
		body: JSON.stringify(body),
	})
		.then((response) => response.json().then((json) => ({ json, response })))
		.then(({ json, response }) => {
			if (!response.ok) {
				return Promise.reject(json);
			}
			return json;
		}) 
		.then(
			(response) => response,
			(error) => error
		);
}
