import React from 'react';

function ListTableHeader(props) {
    return (
        <tr style={{fontSize:"15px"}}>
            <th style={{minWidth: "150px"}}>Name</th>
            <th style={{minWidth: "150px"}}>Minimum Age</th>
            <th style={{minWidth: "150px"}}>Maximum Age</th>
            <th style={{minWidth: "150px"}}>Recommended Calories</th>
            <th style={{minWidth: "120px"}}>remarks</th>
            <th style={{minWidth: "120px"}}>isActive</th>
            <th style={{minWidth: "150px"}}>Action</th>
        </tr>
    );
}

export default ListTableHeader;


