import React, {Component} from 'react'
import Notification, {notify} from 'react-notify-toast'
import {Redirect} from 'react-router-dom'
import callApi from '../../../util/apiCaller'
import {isNameValid, isDescriptionValid} from '../../../util/validation'
import validator from 'validator'
import _ from 'lodash';
import '../DailyMenu/components/Table/Table.css';
import {Button, ButtonGroup} from "react-bootstrap";
import async from 'async';
import {Card} from "react-bootstrap";
const MODEL_NAME = "dailymenu"
const MODELCOMBO= 'combo_menu'
const MODEL_NAME_FOOD = "fooditem"
const MODEL_NAME_COMBO = "combomenu"
const add = "ComboMenu data is Added Successfully"
const server = "Something went wrong"
const update = "ComboMenu data is Updated Successfully"

class MenuAddList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            item : {
                scheduled_date: new Date(),
                meal_type: '',
                thumbnail_img: "",
                big_img: "",
                remarks: "",
               
            },
            first_side_item: '',
            second_side_item: '',
            isAuthenticated: true,
            isLoggingIn: true,
            meal_type: '',
            breakfastCombos: [
                {
                    name: '',
                    main_item: '',
                    first_side_item: '',
                    second_side_item: '',
                    meal_type: ""
                }
            ],
            lunchCombos: [
                {
                    name: '',
                    main_item: '',
                    first_side_item: '',
                    second_side_item: '',
                    meal_type: ""
                }
            ],
            snackCombos: [
                {
                    name: '',
                    main_item: '',
                    first_side_item: '',
                    second_side_item: '',
                    meal_type: ""
                }
            ],
            menu_type: [
                "breakfast", "snack", "lunch"
            ],
            isRefreshing: false,
            isSearching: false,
            isLoading: false,
            pageNum: 1,
            pageSize: 3,
            qtext: "",
            menuTypeText: "",
            mealtype: "",
            id: "",
            scheduleddateerror: "",
            comboerror: "",
            main_itemerror: '',
            nameerror: "",
            first_side_itemerror: '',
            second_side_itemerror: '',
            meal_typeerror: '',
            pageerror: "",
        }
        this.debouncedSearch = _.debounce(this.handleSearch, 400);
    }

    // { dailymenu : { combos: [{  }] } }

    componentDidMount() {
        console.log("vikram",this.state.item);
        if (this.props.match.params.id) {
            // const id = this.props.match.params.id
            // this.fetchMenu(id);
        }
    }


    fetchMenu = (id) => {
        // todo loader
        callApi(`v2/${MODEL_NAME}s/${id}`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                let dailymenu = res.data[MODEL_NAME];
                if (dailymenu.combos && dailymenu.combos.length > 0) {
                    let combos = [];
                    async.each(dailymenu.combos, (id, callback) => {
                        this.fetchComboMenu(id, (err, data) => {
                            if (data) {
                                combos.push(data);
                                return callback();
                            }
                        });
                    }, (err) => {
                        dailymenu.combos = combos;
                        let breakfastCombos = combos.filter(item => item.meal_type === 'breakfast');
                        let lunchCombos = combos.filter(item => item.meal_type === 'lunch');
                        let snackCombos = combos.filter(item => item.meal_type === 'snack');
                        this.setState({
                            item: dailymenu,
                            breakfastCombos: breakfastCombos,
                            lunchCombos: lunchCombos,
                            snackCombos: snackCombos
                        })
                    });
                } else {
                    this.setState({
                        item: dailymenu,
                    });
                }
            } else {
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            console.log(err)
            this.showMessage(`Error on fetching ${MODEL_NAME.toUpperCase()}`, 'error');
        });
    };

    fetchComboMenu = (id, callback) => {
        callApi(`v2/combomenus/${id}`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                // res.data.combo_menu;
                return callback(null, res.data.combo_menu);
            } else {
                return callback(res.data.message);
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            console.log(err)
            this.showMessage(`Error on fetching ${MODEL_NAME.toUpperCase()}`, 'error');
        });
    };

    handleValidation = (item) => {
        let isValid = true;
        let errors = {};
        if (!item.scheduled_date) {
            errors["date"] = "Date is required"
            isValid = false;
        } else if (!validator.isDate(item.scheduled_date)) {
            isValid = false;
            errors["date"] = "please enter a date"
        }
        if (item.meal_type.length < 1) {
            isValid = false;
            errors["meal_type"] = "please enter meal_type in your menu"
        }
        if (item.thumbnail_img.length < 1) {
            isValid = false;
            errors["thumbnail_img"] = "please enter thumbnail_img in your menu"
        }
        if (item.big_img.length < 1) {
            isValid = false;
            errors["big_img"] = "please enter big_img in your menu"
        }
        if (item.remarks.length < 1) {
            isValid = false;
            errors["remarks"] = "please enter remarks in your menu"
        }
        return {
            isValid,
            errors
        }
    };

    handleAddCombo = () => {
        // validate -> errors -> show -> no errors -> apiCall
        let errors = {};
        let item = this.state.item;
        let check = this.handleValidation(item);
        if (check.isValid) {
            this.showMessage("Combo is Added", "success")
            this.addNewItemCombo(item)
        } else {
            this.setState({
                errors: errors
            })
        }
    }

    handleRemoveCombo = (i) => {
        let combo = this.state.combo;
        combo = combo.filter((item, index) => index !== i);
        this.setState({
            combo: combo
        });
    };

    filterItems = (filters) => {
        this.setState({
            isRefreshing: true,
            pageNum: filters.pageNum ? filters.pageNum : this.state.pageNum,
            pageSize: filters.pageSize ? filters.pageSize : this.state.pageSize,
            qtext: filters.qtext
        });
        this.fetchListItems(filters, (err, res) => {
            if (err) {
                console.log(err);
                this.showMessage(`${server}`, "error")
            } else {
                this.setState({
                    items: res.data[MODEL_NAME_FOOD + 's'] ? res.data[MODEL_NAME_FOOD + 's'] : [],
                    count: res.data.count ? res.data.count : 0,
                    isRefreshing: false,
                    isLoading: false,
                    isSearching: false
                });
            }
        });
    };

    handleSearch = () => {
        let filters = {
            pageNum: 1,
            pageSize: this.state.pageSize,
            qtext: this.state.qtext
        };
        console.log(filters)
        if(this.state.qtext){
            this.filterItems(filters);
        }else{
            this.setState({ items: [] })
        }
    };

    fetchListItems = (filters, next) => {
        callApi(`v2/${MODEL_NAME_FOOD}s/list`, 'POST', filters).then(res => {
            return next(null, res);
        }).catch(err => {
            return next(err);
        });
    };

    addNewItemCombo = (item) => {
        this.setState({showModal: false})
        let body = {};
        body[MODELCOMBO] = item;
        callApi(`v2/${MODEL_NAME_COMBO}s/new`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                let newItem = res.data[MODELCOMBO];
                let items = this.state.items;
                items.unshift(newItem);
                this.setState({items: items, count: this.state.count + 1});
                this.showMessage(`${add}`, "success");
            } else {
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            console.error(err);
            this.showMessage(`Error on adding ${MODEL_NAME.toUpperCase()}`, "error");
        });
    };

    addComboMenu = (combomenu, callback) => {
        callApi(`v2/combomenus/new`, 'post', {
            combo_menu : combomenu
        }).then(res => {
            if (res.status === 'Success') {
                return callback(null, res.data.combo_menu);
            } else {
                return callback(res.data.message);
            }
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.props.match.params.id) {
            // _id ? -> exist
            // _id not there ?
            let combos = [];
            this.state.breakfastCombos.forEach(item => {
                combos.push(item);
            });
            this.state.lunchCombos.forEach(item => {
                combos.push(item);
            });
            this.state.snackCombos.forEach(item => {
                combos.push(item);
            });
            let newCombos = combos.filter(item => !item._id);
            let savedCombos = combos.filter(item => item._id).map(item => item._id);
            async.each(newCombos, (combomenu, callback) => {
                this.addComboMenu(combomenu, (err, data) => {
                    if (data) {
                        savedCombos.push(data._id);
                    }
                });
            }, (err) => {
                let item = this.state.item;
                item.combos = savedCombos;
                this.updateItem(this.state.item);
            });
        } else {
            let combos = [];
            this.state.breakfastCombos.forEach(item => {
                combos.push(item);
            });
            this.state.lunchCombos.forEach(item => {
                combos.push(item);
            });
            this.state.snackCombos.forEach(item => {
                combos.push(item);
            });
            let item = this.state.item;
            item.combos = combos;
            this.addNewItem(item);
        }
    }

    addNewItem = (dailymenu) => {
        let body = {};
        body[MODEL_NAME] = dailymenu
        callApi(`v2/${MODEL_NAME}s/new`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                console.log(res)
                this.showMessage(`${add}`, 'success');
            } else {
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            console.error(err);
            this.showMessage(`Error on adding new ${MODEL_NAME.toLowerCase()}`, 'error');
        });
    };

    updateItem = (id, dailymenu) => {
        let body = {};
        body[MODEL_NAME] = dailymenu;
        callApi(`v2/${MODEL_NAME}s/${id}/update`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                console.log(res)
                this.showMessage(`${update}`, 'success');
            } else {
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`, 'error');
        });
    };

    updateComboMenu = (id, combo) => {
        let body = {};
        body[MODEL_NAME] = combo;
        callApi(`v2/combomenus/${id}/update`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                console.log(res)
                this.showMessage(`${update}`, 'success');
            } else {
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`, 'error');
        });
    };

    handleSaveCombo = () =>{
        this.updateComboMenu(this.state.combo._id, this.state.combo)
    }

    showMessage = (msg, msgType) => {
        if (msgType === "success") {
            notify.show(msg, msgType, 3000);
        }
        if (msgType === "error") {
            notify.show(msg, msgType, 3000);
        }
    };

    handleComboChange = (selectedComboType, index, keyName, keyValue) => {
        if (selectedComboType === 'breakfast') {
            let breakfastCombos = this.state.breakfastCombos.map((item, i) => {
                if (i === index) {
                    item[keyName] = keyValue;
                    this.state.qtext = keyValue
                }
                return item;
            });
            this.setState({
                    breakfastCombos: breakfastCombos
                },
                () => {
                    this.debouncedSearch()});

        } else if (selectedComboType === 'lunch') {
            let lunchCombos = this.state.lunchCombos.map((item, i) => {
                if (i === index) {
                    item[keyName] = keyValue;
                    this.state.qtext = keyValue
                }
                return item;
            });
            this.setState({
                    lunchCombos: lunchCombos
                },
                () => {
                    this.debouncedSearch()});
        } else if (selectedComboType === 'snack') {
            let snackCombos = this.state.snackCombos.map((item, i) => {
                if (i === index) {
                    item[keyName] = keyValue;
                    this.state.qtext = keyValue
                }
                return item;
            });
            this.setState({
                    snackCombos: snackCombos
                },
                () => {
                    this.debouncedSearch()});
        }
    };

    handleAddNewCombo = (selectedComboType) => {
        if (selectedComboType === 'breakfast') {
            let breakfastCombos = this.state.breakfastCombos;
            breakfastCombos.push({
                name: '',
                first_side_item:'',
                second_side_item:'',
                meal_type:''
            });
            this.setState({
                breakfastCombos: breakfastCombos
            });
        } else if (selectedComboType === 'lunch') {
            let lunchCombos = this.state.lunchCombos;
            lunchCombos.push({
                name: '',
                first_side_item:'',
                second_side_item:'',
                meal_type:''
            });
            this.setState({
                lunchCombos: lunchCombos
            });
        } else if (selectedComboType === 'snack') {
            let snackCombos = this.state.snackCombos;
            snackCombos.push({
                name: '',
                first_side_item:'',
                second_side_item:'',
                meal_type:''
            });
            this.setState({
                snackCombos: snackCombos
            });
        }
    };

    handleItemChange = (keyName, keyValue) => {
        let item = this.state.item;
        if (keyName === 'scheduled_date') {
            item[keyName] = keyValue;
        } else {
            item[keyName] = keyValue;
        }
        this.setState({
            item: item
        })
    };

    render() {
        console.log("data", this.state);
        let errors = this.state.errors;
        return (
            <>
                <Notification/>
                {this.props.isAuthenticated ?
                    <div className="story_list" style={{
                        marginLeft: this.props.showSidebar ? "240px" : "70px",
                        marginTop: "100px",
                        padding: "20px",
                        minHeight: "80vh"
                    }}>
                        <div className="container-fluid">
                            <h4 className="mb-4" style={{marginTop: "10px"}}>Create Your DailyMenu</h4>
                            <form onSubmit={(e) => this.handleSubmit(e)}>
                                {/* bootstrap row */}
                                
                                    <Card>
                                        <Card.Body className="col">
                                        <div className="col-lg-4 mt-2">
                                        <label for="date" className="form-label">Select date</label>
                                        <input className="form-control" type="date" value={this.state.item && this.state.item.scheduled_date ? this.state.item.scheduled_date : new Date()}
                                               onChange={(e) => this.handleItemChange('scheduled_date', e.target.value)}/>
                                        {errors && errors.scheduled_date ? <small
                                            style={{color: "red"}}>*{errors.scheduled_date}</small> : null}
                                    </div>
                                    <div className="col-lg-4 mt-2">
                                        <label for="meal_type" className="form-label">Meal Type</label>
                                        <input className="form-control" type="text" value={this.state.item.meal_type}
                                               onChange={(e) => this.handleItemChange('meal_type', e.target.value)}/>
                                        {errors && errors.meal_type ? <small
                                            style={{color: "red"}}>*{errors.meal_type}</small> : null}
                                    </div>
                                    <div className="col-lg-4">
                                        <label for="thumbnail_img" className="form-label">Thumbnail Image</label>
                                        <input className="form-control" type="text" value={this.state.item.thumbnail_img}
                                               onChange={(e) => this.handleItemChange('thumbnail_img', e.target.value)}/>
                                        {errors && errors.thumbnail_img ? <small
                                            style={{color: "red"}}>*{errors.thumbnail_img}</small> : null}
                                    </div>
                                    <div className="col-lg-4">
                                        <label for="remarks" className="form-label">Remarks</label>
                                        <input className="form-control" type="text" value={this.state.item.remarks}
                                               onChange={(e) => this.handleItemChange('remarks', e.target.value)}/>
                                        {errors && errors.remarks ? <small
                                            style={{color: "red"}}>*{errors.remarks}</small> : null}
                                    </div>
                                    <div className="col-lg-4">
                                        <label for="big_img" className="form-label">Big Image</label>
                                        <input className="form-control" type="text" value={this.state.item.big_img}
                                               onChange={(e) => this.handleItemChange('big_img', e.target.value)}/>
                                        {errors && errors.big_img ? <small
                                            style={{color: "red"}}>*{errors.big_img}</small> : null}
                                    </div>
                                    <div className="my-3 col-lg-4">
                                        <button type="button" className="btn btn-outline-success" id="breakfast"
                                                onClick={() => this.handleAddCombo()}>Save Combo
                                        </button>
                                    </div>
                                        </Card.Body>
                                    </Card>
                                   
                                

                                <div className="row">
                                    <div className="col-md-4 col-12 col-lg-4">
                                        {this.state.breakfastCombos.map((item, index) => (
                                            <Card>
                                                <Card.Body>
                                                    <input className="col-12 col-lg-12"
                                                        style={{marginTop:"5px", marginBottom:"5px"}}
                                                        placeholder="Enter Name"
                                                        onChange={(e) => this.handleComboChange('breakfast', index, 'name', e.target.value)}
                                                        type="text" value={item.name}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{marginTop:"5px", marginBottom:"5px"}}
                                                        placeholder="select main item"
                                                        onChange={(e) => this.handleComboChange('breakfast', index, 'main_item', e.target.value)}
                                                        type="text" value={item.main_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        placeholder="select first item"
                                                        onChange={(e) => this.handleComboChange('breakfast', index, 'first_side_item', e.target.value)}
                                                        type="text" value={item.first_side_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        placeholder="select second item"
                                                        onChange={(e) => this.handleComboChange('breakfast', index, 'second_side_item', e.target.value)}
                                                        type="text" value={item.second_side_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        onChange={(e) => this.handleComboChange('breakfast', index, 'meal_type', e.target.value)}
                                                        type="text" value={item.meal_type}/>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                    </div>
                                    <div className="col-md-4 col-12 col-lg-4">
                                        {this.state.lunchCombos.map((item, index) => (
                                            <Card>
                                                <Card.Body>
                                                    <input className="col-12 col-lg-12"
                                                        style={{marginTop:"5px", marginBottom:"5px"}}
                                                        placeholder="Enter Name"
                                                        onChange={(e) => this.handleComboChange('lunch', index, 'name', e.target.value)}
                                                        type="text" value={item.name}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{marginTop:"5px", marginBottom:"5px"}}
                                                        placeholder="select main item"
                                                        onChange={(e) => this.handleComboChange('lunch', index, 'main_item', e.target.value)}
                                                        type="text" value={item.main_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        placeholder="select first item"
                                                        onChange={(e) => this.handleComboChange('lunch', index, 'first_side_item', e.target.value)}
                                                        type="text" value={item.first_side_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        placeholder="select second item"
                                                        onChange={(e) => this.handleComboChange('lunch', index, 'second_side_item', e.target.value)}
                                                        type="text" value={item.second_side_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        onChange={(e) => this.handleComboChange('lunch', index, 'meal_type', e.target.value)}
                                                        type="text" value={item.meal_type}/>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                    </div>
                                    <div className="col-md-4 col-12 col-lg-4">
                                        {this.state.snackCombos.map((item, index) => (
                                            <Card>
                                                <Card.Body>
                                                    <input className="col-12 col-lg-12"
                                                        style={{marginTop:"5px", marginBottom:"5px"}}
                                                        placeholder="Enter Name"
                                                        onChange={(e) => this.handleComboChange('snack', index, 'name', e.target.value)}
                                                        type="text" value={item.name}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{marginTop:"5px", marginBottom:"5px"}}
                                                        placeholder="select main item"
                                                        onChange={(e) => this.handleComboChange('snack', index, 'main_item', e.target.value)}
                                                        type="text" value={item.main_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        placeholder="select first item"
                                                        onChange={(e) => this.handleComboChange('snack', index, 'first_side_item', e.target.value)}
                                                        type="text" value={item.first_side_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        placeholder="select second item"
                                                        onChange={(e) => this.handleComboChange('snack', index, 'second_side_item', e.target.value)}
                                                        type="text" value={item.second_side_item}/>
                                                    <input className="col-12 col-lg-12"
                                                        style={{margin:"10px 0px"}}
                                                        onChange={(e) => this.handleComboChange('snack', index, 'meal_type', e.target.value)}
                                                        type="text" value={item.meal_type}/>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                                <div className="row">

                                        <Button
                                            className="col-lg-2 col-md-4 col-12 offset-lg-1"
                                            onClick={() => this.handleAddNewCombo('breakfast')}
                                        >Add Breakfast</Button>
                                        <Button
                                            className="col-lg-2 col-md-4 col-12 offset-lg-2"
                                            onClick={() => this.handleAddNewCombo('lunch')}
                                        >Add Lunch</Button>
                                        <Button
                                            className="col-lg-2 col-md-4 col-12 offset-lg-2"
                                            onClick={() => this.handleAddNewCombo('snack')}
                                        >Add Snack</Button>

                                </div>
                                <div className="mt-4">
                                    <button type="submit" className="btn btn-primary btn-lg">{
                                        this.props.match.params.id ? "Update Menu" : "Save Menu"
                                    }</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    : (
                        <>
                            {<Redirect to="/login"/>}
                        </>
                    )}
            </>
        )
    }
}

export default MenuAddList;