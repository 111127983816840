import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            name:"",
            food_category:"",
            display_name:"",
            cuisine_type:"",
            food_item_variant:"",
            portion_type:"",
            isBreakfast:false,
            isSnack:false,
            isDinner:false,
            thumbnail_image:"",
            big_img:"",
            slideimage_1:"",
            video:"",
            slideimage_2:"",
            slideimage_3:"",
            video_thumbnail:"",
            isApproved:false,
            status:"",
            remarks:"",
            nameerror:"",
            foodcategoryerror:"",
            displaynameerror:"",
            cuisinetypeerror:"",
            fooditemvarienterror:"",
            portiontypeerror:"",
            thumbnailimageerror:"",
            bigimgerror:"",
            slideimage1error:"",
            videoerror:"",
            slideimage2error:"",
            slideimage3error:"",
            videothumbnailerror:"",
            statuserror:"",
            remarkerror:"",
        
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                name: this.props.user.name,
                food_category: this.props.user.food_category,
                display_name: this.props.user.display_name,
                cuisine_type: this.props.user.cuisine_type,
                food_item_variant: this.props.user.food_item_variant,
                portion_type: this.props.user.portion_type,
                isBreakfast: this.props.user.isBreakfast,
                isSnack: this.props.user.isSnack,
                isDinner: this.props.user.isDinner,
                thumbnail_image: this.props.user.thumbnail_image,
                big_img: this.props.user.big_img,
                slideimage_1: this.props.user.slideimage_1,
                video: this.props.user.video,
                slideimage_2: this.props.user.slideimage_2,
                slideimage_3: this.props.user.slideimage_3,
                video_thumbnail: this.props.user.video_thumbnail,
                isApproved: this.props.user.isApproved,
                status : this.props.user.status,
                remarks: this.props.user.remarks,
               
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { name, food_category, display_name, cuisine_type, food_item_variant, portion_type,  thumbnail_image, big_img, slideimage_1, video, slideimage_2, slideimage_3,
               video_thumbnail,  status, remarks } = this.state
        if(name.length < 1){
            error["name"] = "name is required"
        }else if(!isNameValid(name)){
            error["name"] = "Name only contain letters and Space"
        }
        if(food_category.length < 1){
            error["food_category"] = "food_category is required"
        }
        if(display_name.length < 1){
            error["display_name"] = "display_name is required"
        }
        if(cuisine_type.length < 1){
            error["cuisine_type"] = "cuisine_type is required"
        }
        if(food_item_variant.length < 1){
            error["food_item_variant"] = "food_item_variant is required"
        }
        if(portion_type.length < 1){
            error["portion_type"] = "portion_type is required"
        }
       
        if(thumbnail_image.length < 1){
            error["thumbnail_image"] = "thumbnail_image is required"
        }
        if(big_img.length < 1){
            error["big_img"] = "big_img is required"
        }
        if(slideimage_1.length < 1){
            error["slideimage_1"] = "slideimage_1 is required"
        }
        if(video.length < 1){
            error["video"] = "video is required"
        }
        if(slideimage_2.length < 1){
            error["slideimage_2"] = "slideimage_2 is required"
        }
        if(slideimage_3.length < 1){
            error["slideimage_3"] = "slideimage_3 is required"
        }
        if(video_thumbnail.length < 1){
            error["video_thumbnail"] = "video_thumbnail is required"
        }
        if(remarks.length < 1){
            error["remark"] = "remark is required"
        }
        if(status.length < 1){
            error["status"] = "status  is required"
        }
        if(error["name"] || error["food_category"] || error["display_name"] || error["cuisine_type"] || error["food_item_variant"] || error["portion_type"] ||  error["thumbnail_image"] || error["big_img"] || error["slideimage_1"] || error["video"] ||
        error["slideimage_2"] || error["slideimage_3"] || error["video_thumbnail"] || error["isApproved"] || error["remark"] || error["status"] ){
            this.setState({
                nameerror: error["name"],
                foodcategoryerror: error["food_category"],
                displaynameerror: error["display_name"],
                cuisinetypeerror: error["cuisine_type"],
                fooditemvarienterror: error["food_item_variant"],
                portiontypeerror: error["portion_type"],
                thumbnailimageerror: error["thumbnail_image"],
                bigimgerror: error["big_img"],
                slideimage1error: error["slideimage_1"],
                videoerror: error["video"],
                slideimage2error: error["slideimage_2"],
                slideimage3error: error["slideimage_3"],
                videothumbnailerror: error["video_thumbnail"],
                remarkerror: error["remark"],
                statuserror: error["status"]
            })
        }else{
           
            let body = {
                name: name,
                food_category: food_category,
                display_name: display_name,
                cuisine_type: cuisine_type,
                food_item_variant: food_item_variant,
                portion_type: portion_type,
                isBreakfast: this.state.isBreakfast,
                isSnack: this.state.isSnack,
                isDinner: this.state.isDinner,
                thumbnail_image: thumbnail_image,
                big_img: big_img,
                slideimage_1: slideimage_1,
                video: video,
                slideimage_2: slideimage_2,
                slideimage_3: slideimage_3,
                video_thumbnail: video_thumbnail,
                isApproved: this.state.isApproved,
                remarks: remarks,
                status: status
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    name:"",
                    food_category:"",
                    display_name:"",
                    cuisine_type:"",
                    food_item_variant:"",
                    portion_type:"",
                    isBreakfast:"",
                    isSnack:"",
                    isDinner:"",
                    thumbnail_image:"",
                    big_img:"",
                    slideimage_1:"",
                    video:"",
                    slideimage_2:"",
                    slideimage_3:"",
                    video_thumbnail:"",
                    isApproved:"",
                    remarks:"",
                    status:""
                })
            }        
        }
    }
    
    render() {
    
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="name">Enter name</label>
                                <input 
                                    type="text" 
                                    value={this.state.name} 
                                    onChange={(e) => this.setState({ name: e.target.value, nameerror:e.target.value.length > 0? null: this.state.nameerror })} />
                                {this.state.nameerror ? <small style={{color: "red"}}>*{this.state.nameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="food_category">Enter food_category</label>
                                <input 
                                    type="text" 
                                    value={this.state.food_category} 
                                    onChange={(e) => this.setState({ food_category: e.target.value, foodcategoryerror:e.target.value.length > 0? null: this.state.foodcategoryerror })} />
                                {this.state.foodcategoryerror ? <small style={{color: "red"}}>*{this.state.foodcategoryerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="display_name">Enter display_name</label>
                                <input 
                                    type="text" 
                                    value={this.state.display_name} 
                                    onChange={(e) => this.setState({ display_name: e.target.value, displaynameerror:e.target.value.length > 0? null: this.state.displaynameerror })} />
                                {this.state.displaynameerror ? <small style={{color: "red"}}>*{this.state.displaynameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="cuisine_type">Enter cuisine_type</label>
                                <input 
                                    type="text" 
                                    value={this.state.cuisine_type} 
                                    onChange={(e) => this.setState({ cuisine_type: e.target.value, cuisinetypeerror:e.target.value.length > 0? null: this.state.cuisinetypeerror })} />
                                {this.state.cuisinetypeerror ? <small style={{color: "red"}}>*{this.state.cuisinetypeerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="food_item_variant">Enter food_item_variant</label>
                                <input 
                                    type="text" 
                                    value={this.state.food_item_variant} 
                                    onChange={(e) => this.setState({ food_item_variant: e.target.value, fooditemvarienterror:e.target.value.length > 0? null: this.state.fooditemvarienterror })} />
                                {this.state.fooditemvarienterror ? <small style={{color: "red"}}>*{this.state.fooditemvarienterror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="portion_type">Enter portion_type</label>
                                <input 
                                    type="text" 
                                    value={this.state.portion_type} 
                                    onChange={(e) => this.setState({ portion_type: e.target.value, portiontypeerror:e.target.value.length > 0? null: this.state.portiontypeerror })} />
                                {this.state.portiontypeerror ? <small style={{color: "red"}}>*{this.state.portiontypeerror}</small> : null}
                            </div>
                           <div className="input_box">
                                    <label htmlFor="isActive" className="form-label">isBreakfast</label>
                                    <select
                                        onChange={(e) => this.setState({ isBreakfast: e.target.value })}
                                        className="form-control" id="isBreakfast"
                                        value={this.state.isBreakfast}>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                                <div className="input_box">
                                    <label htmlFor="isSnacks" className="form-label">isSnacks</label>
                                    <select
                                        onChange={(e) => this.setState({ isSnacks: e.target.value })}
                                        className="form-control" id="isSnacks"
                                        value={this.state.isSnacks}>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                                <div className="input_box">
                                    <label htmlFor="isDinner" className="form-label">isDinner</label>
                                    <select
                                        onChange={(e) => this.setState({ isDinner: e.target.value })}
                                        className="form-control" id="isDinner"
                                        value={this.state.isDinner}>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                           <div className="input_box">
                                <label htmlFor="thumbnail_image">Enter thumbnail_image</label>
                                <input 
                                type="text" 
                                value={this.state.thumbnail_image} 
                                onChange={(e) => this.setState({ thumbnail_image: e.target.value, thumbnailimageerror:e.target.value.length > 0? null: this.state.thumbnailimageerror })} />
                                {this.state.thumbnailimageerror ? <small style={{color: "red"}}>*{this.state.thumbnailimageerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="big_img">Enter big_img</label>
                                <input 
                                type="text" 
                                value={this.state.big_img} 
                                onChange={(e) => this.setState({ big_img: e.target.value, bigimgerror:e.target.value.length > 0? null: this.state.bigimgerror })} />
                                {this.state.bigimgerror ? <small style={{color: "red"}}>*{this.state.bigimgerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="slideimage_1">Enter slideimage_1</label>
                                <input 
                                    type="text" 
                                    value={this.state.slideimage_1} 
                                    onChange={(e) => this.setState({ slideimage_1: e.target.value, slideimage1error:e.target.value.length > 0? null: this.state.slideimage1error })} />
                                {this.state.slideimage1error ? <small style={{color: "red"}}>*{this.state.slideimage1error}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="video">Enter video</label>
                                <input  
                                    type="text" 
                                    value={this.state.video} 
                                    onChange={(e) => this.setState({ video: e.target.value, videoerror:e.target.value.length > 0? null: this.state.videoerror })} />
                                {this.state.videoerror ? <small style={{color: "red"}}>*{this.state.videoerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="slideimage_2">Enter slideimage_2</label>
                                <input 
                                    type="text" 
                                    value={this.state.slideimage_2} 
                                    onChange={(e) => this.setState({ slideimage_2: e.target.value, slideimage2error:e.target.value.length > 0? null: this.state.slideimage2error })} />
                                {this.state.slideimage2error ? <small style={{color: "red"}}>*{this.state.slideimage2error}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="slideimage_3">Enter slideimage_3</label>
                                <input 
                                    type="text" 
                                    value={this.state.slideimage_3} 
                                    onChange={(e) => this.setState({ slideimage_3: e.target.value, slideimage3error:e.target.value.length > 0? null: this.state.slideimage3error })} />
                                {this.state.slideimage3error ? <small style={{color: "red"}}>*{this.state.slideimage3error}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="video_thumbnail">Enter video_thumbnail</label>
                                <input 
                                    type="text" 
                                    value={this.state.video_thumbnail} 
                                    onChange={(e) => this.setState({ video_thumbnail: e.target.value, videothumbnailerror:e.target.value.length > 0? null: this.state.videothumbnailerror })} />
                                {this.state.videothumbnailerror ? <small style={{color: "red"}}>*{this.state.videothumbnailerror}</small> : null}
                            </div>
                            <div className="input_box">
                                    <label htmlFor="isApproved" className="form-label">isApproved</label>
                                    <select
                                        onChange={(e) => this.setState({ isApproved: e.target.value })}
                                        className="form-control" id="isApproved"
                                        value={this.state.isApproved}>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input 
                                    type="text" 
                                    value={this.state.remarks} 
                                    onChange={(e) => this.setState({ remarks: e.target.value, remarkerror:e.target.value.length > 0? null: this.state.remarkerror })} />
                                {this.state.remarkerror ? <small style={{color: "red"}}>*{this.state.remarkerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="status">Enter status</label>
                                <input 
                                    type="number" 
                                    value={this.state.status} 
                                    onChange={(e) => this.setState({ status: e.target.value, statuserror:e.target.value.length > 0? null: this.state.statuserror })} />
                                {this.state.statuserror ? <small style={{color: "red"}}>*{this.state.statuserror}</small> : null}
                            </div>
                            
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
