import React from 'react';

function ListTableHeader(props) {
    return (
        <tr style={{fontSize:"15px"}}>
            <th style={{minWidth: "150px"}}>Menu Type</th>
            <th style={{minWidth: "150px"}}>Main</th>
            <th style={{minWidth: "150px"}}>Side1</th>
            <th style={{minWidth: "150px"}}>Side2</th>
            <th style={{minWidth: "150px"}}>Combo</th>
            <th style={{minWidth: "120px"}}>Actual</th>
            <th style={{minWidth: "150px"}}>Scheduled Date</th>
            <th style={{minWidth: "150px"}}>Order Type ID</th>
            <th style={{minWidth: "150px"}}>Action</th>
        </tr>
    );
}

export default ListTableHeader;


