import React, {Component} from 'react';
import EditIcon from '@material-ui/icons/Edit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from "@material-ui/icons/Visibility"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {
        return (
            <tr className="">
                <td style={{minWidth: "150px", fontWeight: "500"}}>{this.props.food.name}</td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.food.food_category}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.display_name}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.cuisine_type}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.food_item_variant}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.portion_type}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.isBreakfast? <FiberManualRecordIcon fontSize="small" htmlColor="green" /> : <FiberManualRecordIcon htmlColor="red" fontSize="small" />}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.isSnack? <FiberManualRecordIcon fontSize="small" htmlColor="green" /> : <FiberManualRecordIcon htmlColor="red" fontSize="small" />}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.isDinner? <FiberManualRecordIcon fontSize="small" htmlColor="green" /> : <FiberManualRecordIcon htmlColor="red" fontSize="small" />}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.thumbnail_image}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.big_img}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.slideimage_1}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.video}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.slideimage_2}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.slideimage_3}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.video_thumbnail}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.isApproved? <FiberManualRecordIcon fontSize="small" htmlColor="green" /> : <FiberManualRecordIcon htmlColor="red" fontSize="small" />}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.status}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.food.remarks}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                View More
                            </Tooltip>
                        }
                    >
                        {/* <VisibilityIcon onClick={() => this.props.handleWSidebar(this.props.food._id)} fontSize="small" style={{ color: "green", marginRight: "15px", cursor: "pointer" }} /> */}
                        <VisibilityIcon onClick={() => this.props.handleFoodSidebar(this.props.food._id)} fontSize="small" style={{ color: "green", marginRight: "15px", cursor: "pointer" }} />
                    </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          Edit
                        </Tooltip>
                    }
                    >
                    <EditIcon onClick={() => this.props.displayModal(this.props.food._id)} fontSize="small" style={{color: "#3399ff", marginRight: "15px", cursor: "pointer"}} />
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          Delete
                        </Tooltip>
                    }
                    >
                    <DeleteIcon onClick={() => this.props.deleteUser(this.props.food._id)} fontSize="small" style={{color: "#d81212", cursor: "pointer"}} />
                </OverlayTrigger>
                </td>
                
            </tr>
        );
    }
}

export default ListTableRow;


