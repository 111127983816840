import React, {Component} from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from "@material-ui/icons/Visibility"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {
        return (
            <tr className="">
                <td style={{minWidth: "150px", fontWeight: "500"}}>{this.props.user.fooditem_id}</td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.serving_number}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                    <a>{this.props.user.preparation_quantity}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                    <a>{this.props.user.ingredient_quantity}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                    <a>{this.props.user.calorie}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                    <a>{this.props.user.protein}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                    <a>{this.props.user.fats}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                    <a>{this.props.user.iron}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                    <a>{this.props.user.calcium}</a>
                </td>
                <td style={{minWidth: "100px"}}>
                    <a>{this.props.user.carbohydrates}</a>
                </td>
                <td style={{minWidth: "100px"}}>
                    <a>{this.props.user.fibre}</a>
                </td>
                <td style={{minWidth: "100px"}}>
                    <a>{this.props.user.measurement}</a>
                </td>
                <td style={{minWidth: "100px"}}>
                    <a>{this.props.user.sodium}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                        <a>{this.props.user.remarks}</a>
                    </td>
                <td style={{minWidth: "80px"}}>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          Edit
                        </Tooltip>
                    }
                    >
                    <EditIcon onClick={() => this.props.displayModal(this.props.user._id)} fontSize="small" style={{color: "#3399ff", marginRight: "15px", cursor: "pointer"}} />
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          Delete
                        </Tooltip>
                    }
                    >
                    <DeleteIcon onClick={() => this.props.deleteUser(this.props.user._id)} fontSize="small" style={{color: "#d81212", cursor: "pointer"}} />
                </OverlayTrigger>
                </td>
                
            </tr>
        );
    }
}

export default ListTableRow;


