import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import callApi from '../../../../../util/apiCaller';
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

const MODEL_NAME = 'fooditem'
const MODEL= 'food_items'
export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            foodItemID:"",
            serving_number:"",
            preparation_quantity:"",
            ingredient_quantity:"",
            calorie:"",
            protein:"",
            fats:"",
            iron:"",
            calcium:"",
            carbohydrates:"",
            fibre:"",
            measurement:"",
            sodium:"",
            remarks:"",
            servingnumbererror:"",
            preprationquantityerror:"",
            ingredientquantityerror:"",
            calorieerror:"",
            proteinerror:"",
            fatserror:"",
            ironerror:"",
            calciumerror:"",
            carbohydrateserror:"",
            fibreerror:"",
            measurementerror:"",
            sodiumerror:"",
            remarkerror:"",
           
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                serving_number: this.props.user.serving_number,
                preparation_quantity: this.props.user.preparation_quantity,
                ingredient_quantity: this.props.user.ingredient_quantity,
                calorie: this.props.user.calorie,
                protein: this.props.user.protein,
                fats: this.props.user.fats,
                iron: this.props.user.iron,
                calcium: this.props.user.calcium,
                carbohydrates: this.props.user.carbohydrates,
                fibre: this.props.user.fibre,
                measurement: this.props.user.measurement,
                sodium: this.props.user.sodium,
                remarks: this.props.user.remarks,
        

            })
        }
        
            callApi(`v2/${MODEL_NAME}s/list`, 'POST').then(res => {
                if (res && res.status === 'Success') {
                    let newItem = res.data[MODEL];
                    this.setState({ foodItemID: newItem._id }, () => console.log("FoodItemID",this.state.foodItemID))
                    
                }else{
                    // alert(`${server}`)
                }
            }).catch(err => {
                this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`);
            });
        
    }

   

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { serving_number,preparation_quantity, ingredient_quantity, calorie, protein, fats, iron, calcium, carbohydrates, fibre, measurement, sodium, remarks } = this.state
        if(serving_number.length < 1){
            error["serving_number"] = "serving_number is required"
        }
        if(preparation_quantity.length < 1){
            error["preparation_quantity"] = "preparation_quantity Number is required"
        }
        if(ingredient_quantity.length < 1){
            error["ingredient_quantity"] = "ingredient_quantity is required"
        }
        if(calorie.length < 1){
            error["calorie"] = "calorie is required"
        }
        if(protein.length < 1){
            error["protein"] = "protein is required"
        }
        if(fats.length < 1){
            error["fats"] = "fats is required"
        }
        if(iron.length < 1){
            error["iron"] = "iron is required"
        }
        if(calcium.length < 1){
            error["calcium"] = "calcium is required"
        }
        if(carbohydrates.length < 1){
            error["carbohydrates"] = "carbohydrates is required"
        }
        if(fibre.length < 1){
            error["fibre"] = "fibre is required"
        }
        if(measurement.length < 1){
            error["measurement"] = "measurement is required"
        }
        if(sodium.length < 1){
            error["sodium"] = "sodium is required"
        }
        if(remarks.length < 1){
            error["remark"] = "remark is required"
        }
        
        if(error["serving_number"] || error["preparation_quantity"] || error["ingredient_quantity"] || error["calorie"] || error["protein"] || error["fats"] || error["fibre"] ||
           error["iron"] || error["calcium"] || error["carbohydrates"] || error["measurement"] || error["sodium"]){
            this.setState({
                servingnumbererror: error["serving_number"],
                preprationquantityerror: error["preparation_quantity"],
                ingredientquantityerror: error["ingredient_quantity"],
                calorieerror: error["calorie"],
                proteinerror: error["protein"],
                fatserror: error["fats"],
                ironerror: error["iron"],
                calciumerror: error["calcium"],
                carbohydrateserror: error["carbohydrates"],
                fibreerror: error["fibre"],
                measurementerror: error["measurement"],
                sodiumerror: error["sodium"],
                remarkerror: error["remark"],
            
            })
        }else{
            
            let body = {
                serving_number: serving_number,
                preparation_quantity: preparation_quantity,
                ingredient_quantity: ingredient_quantity,
                calorie: calorie,
                protein: protein,
                fats: fats,
                iron: iron,
                calcium: calcium,
                carbohydrates: carbohydrates,
                fibre: fibre,
                measurement: measurement,
                sodium: sodium,
                remarks: remarks,
                isDeleted: false
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    serving_number:"",
                    preparation_quantity:"",
                    ingredient_quantity:"",
                    calorie:"",
                    protein:"",
                    fats:"",
                    iron:"",
                    calcium:"",
                    carbohydrates:"",
                    fibre:"",
                    measurement:"",
                    sodium:"",
                    remarks:"",
                    
                })
            }        
        }
    }
    
    render() {
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="serving_number">Enter Serving_number</label>
                                <input type="number" value={this.state.serving_number} onChange={(e) => this.setState({ serving_number: e.target.value, servingnumbererror:e.target.value.length > 0? null: this.state.servingnumbererror })} />
                                {this.state.servingnumbererror ? <small style={{color: "red"}}>*{this.state.servingnumbererror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="preparation_quantity">Enter preparation_quantity</label>
                                <input type="number" value={this.state.preparation_quantity} onChange={(e) => this.setState({ preparation_quantity: e.target.value, preprationquantityerror:e.target.value.length > 0? null: this.state.preprationquantityerror })} />
                                {this.state.preprationquantityerror ? <small style={{color: "red"}}>*{this.state.preprationquantityerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="ingredient_quantity">Enter ingredient_quantity</label>
                                <input type="number" value={this.state.ingredient_quantity} onChange={(e) => this.setState({ ingredient_quantity: e.target.value, ingredientquantityerror:e.target.value.length > 0? null: this.state.ingredientquantityerror })} />
                                {this.state.ingredientquantityerror ? <small style={{color: "red"}}>*{this.state.ingredientquantityerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="calorie">Enter calorie</label>
                                <input type="number" value={this.state.calorie} onChange={(e) => this.setState({ calorie: e.target.value, calorieerror:e.target.value.length > 0? null: this.state.calorieerror })} />
                                {this.state.calorieerror ? <small style={{color: "red"}}>*{this.state.calorieerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="protein">Enter protein</label>
                                <input type="number" value={this.state.protein} onChange={(e) => this.setState({ protein: e.target.value, proteinerror:e.target.value.length > 0? null: this.state.proteinerror })} />
                                {this.state.proteinerror ? <small style={{color: "red"}}>*{this.state.proteinerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="fats">Enter fats</label>
                                <input type="number" 
                                value={this.state.fats} 
                                onChange={(e) => this.setState({ fats: e.target.value, fatserror:e.target.value.length > 0? null: this.state.fatserror })} />
                                {this.state.fatserror ? <small style={{color: "red"}}>*{this.state.fatserror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="iron">Enter iron</label>
                                <input type="number" 
                                    value={this.state.iron} 
                                    onChange={(e) => this.setState({ iron: e.target.value, ironerror:e.target.value.length > 0? null: this.state.ironerror })} />
                                {this.state.ironerror ? <small style={{color: "red"}}>*{this.state.ironerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="calcium">Enter calcium</label>
                                <input type="number" 
                                    value={this.state.calcium} 
                                    onChange={(e) => this.setState({ calcium: e.target.value, calciumerror:e.target.value.length > 0? null: this.state.calciumerror })} />
                                {this.state.calciumerror ? <small style={{color: "red"}}>*{this.state.calciumerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="carbohydrates">Enter carbohydrates</label>
                                <input type="number" 
                                    value={this.state.carbohydrates} 
                                    onChange={(e) => this.setState({ carbohydrates: e.target.value, carbohydrateserror:e.target.value.length > 0? null: this.state.carbohydrateserror })} />
                                {this.state.carbohydrateserror ? <small style={{color: "red"}}>*{this.state.carbohydrateserror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="fibre">Enter fibre</label>
                                <input type="number" 
                                    value={this.state.fibre} 
                                    onChange={(e) => this.setState({ fibre: e.target.value, fibreerror:e.target.value.length > 0? null: this.state.fibreerror })} />
                                {this.state.fibreerror ? <small style={{color: "red"}}>*{this.state.fibreerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="measurement">Enter measurement</label>
                                <input 
                                    type="number" 
                                    value={this.state.measurement} 
                                    onChange={(e) => this.setState({ measurement: e.target.value, measurementerror:e.target.value.length > 0? null: this.state.measurementerror })} />
                                {this.state.measurementerror ? <small style={{color: "red"}}>*{this.state.measurementerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="sodium">Enter sodium</label>
                                <input 
                                    type="number" 
                                    value={this.state.sodium} 
                                    onChange={(e) => this.setState({ sodium: e.target.value, sodiumerror:e.target.value.length > 0? null: this.state.sodiumerror })} />
                                {this.state.sodiumerror ? <small style={{color: "red"}}>*{this.state.sodiumerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input 
                                    type="text" 
                                    value={this.state.remarks} 
                                    onChange={(e) => this.setState({ remarks: e.target.value, remarkerror:e.target.value.length > 0? null: this.state.remarkerror })} />
                                {this.state.remarkerror ? <small style={{color: "red"}}>*{this.state.remarkerror}</small> : null}
                            </div>

                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
