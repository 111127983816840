import React, {Component} from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import VisibilityIcon from "@material-ui/icons/Visibility"
import moment from 'moment'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {
        return (
            <tr className="">
                <td style={{minWidth: "150px", fontWeight: "500"}}>{moment(this.props.user.scheduled_date).format("DD/MM/YYYY")}</td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.combo}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.user.meal_type}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.user.thumbnail_img}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.user.big_img}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.user.remark}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.user.isActive? <FiberManualRecordIcon fontSize="small" htmlColor="green" /> : <FiberManualRecordIcon htmlColor="red" fontSize="small" />}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          View More
                        </Tooltip>
                    }
                    >
                    <VisibilityIcon onClick={() => this.props.handleShowShell(this.props.user._id)} fontSize="small" style={{color: "#045404", marginRight: "15px", cursor: "pointer"}} />
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          Edit
                        </Tooltip>
                    }
                    >
                    <EditIcon onClick={() => this.props.displayModal(this.props.user._id)} fontSize="small" style={{color: "#3399ff", marginRight: "15px", cursor: "pointer"}} />
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          Delete
                        </Tooltip>
                    }
                    >
                    <DeleteIcon onClick={() => this.props.deleteUser(this.props.user._id)} fontSize="small" style={{color: "#d81212", cursor: "pointer"}} />
                </OverlayTrigger>
                </td>
                
            </tr>
        );
    }
}

export default ListTableRow;


