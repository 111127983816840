import React from 'react';

function ListTableHeader(props) {
    return (
        <tr style={{fontSize:"15px"}}>
            {/* <th style={{minWidth: "150px"}}>FoodItemID</th> */}
            <th style={{minWidth: "150px"}}>OrderType</th>
            <th style={{minWidth: "150px"}}>Quantity</th>
            <th style={{minWidth: "150px"}}>Measurement</th>
            <th style={{minWidth: "120px"}}>remark</th>
            <th style={{minWidth: "120px"}}>isActive</th>
            <th style={{minWidth: "150px"}}>Action</th>
        </tr>
    );
}

export default ListTableHeader;


