import React, { Component } from 'react'
import callApi from '../../../../util/apiCaller'
import FoodItemAllergies from './FoodItemAllergies'
import FoodItemIngredients from './FoodItemIngredients'
import FoodItemNutrition from './FoodItemNutrition'
import FoodItemStandardWT from './FoodItemStandardWT'

const add = "Added"
const server = "Something went wrong"
const update = "Updated"
const deleted = "Deleted"
const refresh = "Refreshed"

const foodSidebar = {
    position: "fixed",
    top: "72px",
    left: 0,
    zIndex: 901,
    background: "rgba(0,0,0,0.25)",
    width: "100%",
    height: "calc(100vh - 70px)"
}

const foodSidebarContent = { 
    position: "fixed",
    top: "72px",
    right: 0,
    zIndex: 902,
    width: "1000px", 
    maxWidth: "90%", 
    height: "100%", 
    backgroundColor: "white", 
    float: "right"
}

export class FoodSidebar extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            
        }
    }
    
    componentDidMount(){
        console.log("sidebar", this.props.foodItemType)
    }

    

    render() {
        return (
            <>
            <div id="foodSidebar" style={foodSidebar} onClick={() => this.props.handleFoodSidebar()}>

            </div>
            <div className="foodSidebarContent" style={foodSidebarContent}>
                <div className="row">
                    {/* tab start */}
                    <div className="col-3">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a onClick={() => this.props.getFoodItemType(this.props.foodItem._id, 'food_item_nutrition_infos', 'fooditemnutritioninfo', 1)} className="nav-link active" id="v-pills-nutrition-tab" data-toggle="pill" href="#v-pills-nutrition" role="tab" aria-controls="v-pills-nutrition" aria-selected="true">
                                Nutrition
                            </a>
                            <a onClick={() => this.props.getFoodItemType(this.props.foodItem._id, 'food_item_ingredientss', 'fooditemingredients', 2)} className="nav-link" id="v-pills-ingredients-tab" data-toggle="pill" href="#v-pills-ingredients" role="tab" aria-controls="v-pills-ingredients" aria-selected="false">
                                Ingredients
                            </a>
                            <a onClick={() => this.props.getFoodItemType(this.props.foodItem._id, 'food_item_allergies_infos', 'fooditemallergiesinfo', 3)} className="nav-link" id="v-pills-allergies-tab" data-toggle="pill" href="#v-pills-allergies" role="tab" aria-controls="v-pills-allergies" aria-selected="false">
                                Allergies
                            </a>
                            <a onClick={() => this.props.getFoodItemType(this.props.foodItem._id, 'food_item_standard_wts', 'fooditemstandardwt', 4)} className="nav-link" id="v-pills-standard-tab" data-toggle="pill" href="#v-pills-standard" role="tab" aria-controls="v-pills-standard" aria-selected="false">
                                Standard
                            </a>
                        </div>
                    </div>
                    {/* tab end */}

                    {/* content start */}
                    <div className="col-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            {/* nutrition start */}
                            <div className="tab-pane fade show active" id="v-pills-nutrition" role="tabpanel" aria-labelledby="v-pills-nutrition-tab">
                                <FoodItemNutrition foodItem={this.props.foodItem} data={this.props.foodItemType.nutrition} />
                            </div>
                            {/* nutrition end */}

                            {/* ingredients start */}
                            <div className="tab-pane fade" id="v-pills-ingredients" role="tabpanel" aria-labelledby="v-pills-ingredients-tab">
                                <FoodItemIngredients foodItem={this.props.foodItem} data={this.props.foodItemType.ingredients} />
                            </div>
                            {/* ingredients end */}

                            {/* allergies start */}
                            <div className="tab-pane fade" id="v-pills-allergies" role="tabpanel" aria-labelledby="v-pills-allergies-tab">
                                <FoodItemAllergies foodItem={this.props.foodItem} data={this.props.foodItemType.standard} />
                            </div>
                            {/* allergies end */}

                            {/* standard start */}
                            <div className="tab-pane fade" id="v-pills-standard" role="tabpanel" aria-labelledby="v-pills-standard-tab">
                                <FoodItemStandardWT foodItem={this.props.foodItem} data={this.props.foodItemType.standard} />
                            </div>
                            {/* standard end */}
                        </div>
                    </div>
                    {/* content end */}
                </div>
            </div>
            </>
        )
    }
}

export default FoodSidebar
