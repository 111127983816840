import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			liStyle: {
				padding:"10px",
				fontSize:"1.4rem"
			},
			links: [
				// {
				//     icon: 'ri-building-4-line ',
				//     name: 'Staff',
				//     url: '/staff',
				//     isSubmenuPresent: true,
				//     submenus: [
				//         {
				//             name: 'Manager',
				//             url: '/staff/manager'
				//         },
				//         {
				//             name: 'Supervisor',
				//             url: '/staff/supervisor'
				//         },
				//         {
				//             name: 'Cleaner',
				//             url: '/staff/cleaner'
				//         }
				//     ],
				// },
				{
					icon: ' ri-wallet-line ',
					name: 'Customer',
					url: '/customer',
					isSubmenuPresent: true,
					submenus: [
						{
							icon: ' ri-wallet-line ',
							name: 'Bookings',
							url: '/customer/bookings',
							isSubmenuPresent: false,
						},
						{
							name: 'Payments',
							url: '/customer/payments',
						},
					],
				},
				{
					icon: ' ri-wallet-line ',
					name: 'Packages',
					url: '/servicepackages',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'ServicePackages',
							url: '/servicepackages',
						},
						{
							name: 'AddServicePackage',
							url: '/servicepackageadd',
						},
					],
				},
				{
					icon: ' ri-wallet-line ',
					name: 'Vehicles',
					url: '#',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'Cars',
							url: '/vehicles/cars',
						},
						{
							name: 'Bikes',
							url: '/vehicles/bikes',
						},
					],
				},
				{
					icon: 'ri-find-replace-fill',
					name: 'Places',
					url: '/places',
					isSubmenuPresent: true,
					submenus: [
						{
							name: 'Locations',
							url: '/places/locations',
						},
						{
							name: 'Communities',
							url: '/places/apartments',
						},
						{
							name: 'Blocks',
							url: '/places/blocks',
						},
					],
				},
				{
					icon: 'ri-dashboard-line',
					name: 'Profile',
					url: '/profile',
					isSubmenuPresent: false,
				},
			],
		};
	}


	render() {
		return (
		
			<div className="vertical-menu" id="mobile_sidebar" style={{ width:this.props.iconShow? "240px":"70px"}}>
			<div data-simplebar className="h-100">
			  {/*- Sidemenu */}
			  <div className="simplebar-offset" style={{right: '-17px', bottom: '0px'}}>
				  <div className="simplebar-content-wrapper">
					  <div className="simplebar-content" style={{padding: '0px'}}>
			  <div id="sidebar-menu">
			
				{/* Left Menu Start */}
				<ul className="metismenu list-unstyled" id="side-menu">
					<li>
						<Link to="/" className=" waves-effect">
						<i className="ri-dashboard-line" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Home</span> : null }
						</Link>
				  	</li>

				  	<li>
						<Link to="/users"  className="waves-effect">
						<i className="ri-calendar-2-line" aria-hidden="true" style={this.state.liStyle} />
						
						{ this.props.iconShow ? <span style={{display: "inline-block",width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>User</span> : null }
						</Link>
				  	</li>

				  	<li>
						<Link to="/activitycenter" className=" waves-effect">
						<i className="ri-chat-1-line" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>ActivityCenter</span> : null }
						</Link>
				  	</li>

					<li>
						<Link to="/combomenu" className=" waves-effect">
						<i className="ri-store-2-line" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>ComboMenu</span> : null }
						</Link>
					</li>

					<li>
						<Link to="/dailymenu" className="has-arrow waves-effect">
						<i className="ri-mail-send-line" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Daily Menu</span> : null }
						</Link>
					</li>

					<li>
						<Link to="/foodcategory" className="has-arrow waves-effect">
						<i className="ri-artboard-2-line" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Food Category</span> : null }
						</Link>
					</li>

					<li>
						<Link to="/cuisine" className="has-arrow waves-effect">
						<i className="ri-layout-3-line" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Cuisines</span> : null }
						</Link>
					</li>

					<li>
						<Link to="/allergy" className="has-arrow waves-effect">
						<i className="ri-account-circle-line" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Allergy Master</span> : null }
						</Link>
					</li>

					<li>
						<Link to="/fooditem" className="has-arrow waves-effect">
						<i className="ri-account-circle-line" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px",position:"relative",bottom:"7px",fontSize:"14px"}}>Food Item</span> : null }
						</Link>   
					</li>

					<li>
						<Link to="/menuportion" className="has-arrow waves-effect">
						<i className="fa fa-address-book-o" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px"}}>Menu Portion</span> : null }
						</Link>
					</li>

					<li>
						<Link to="/fooditemingredient" className="has-arrow waves-effect">
						<i className="fa fa-address-book-o" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px"}}>Food Item Ingredient</span> : null }
						</Link>
					</li>

				  	<li>
						<Link to="/fooditemportioning" className="has-arrow waves-effect">
						<i className="fa fa-address-book-o" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px"}}>Food Item Portioning</span> : null }
						</Link>
					</li>

					<li>
						<Link to="/ordertypemaster" className="has-arrow waves-effect">
						<i className="fa fa-address-book-o" aria-hidden="true" style={this.state.liStyle} />
						{ this.props.iconShow ? <span style={{width:"100px"}}>Order Type Master</span> : null }
						</Link>
				  	</li>

					<li>
						<Link to="/ingredientmaster" className="has-arrow waves-effect">
							<i className="fa fa-address-book-o" aria-hidden="true" style={{ padding: "10px", fontSize: "20px" }} />
							{this.props.iconShow ? <span style={{ width: "100px" }}>Ingredient Master</span> : null}
						</Link>
					</li>

				</ul>
			  </div>
			  {/* Sidebar */}
			</div>
			</div>
			</div>

			</div>
			</div>
		
		);
	}
}

export default Sidebar;