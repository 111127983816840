import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            name:"",
            minimum_age:"",
            maximum_age:"",
            recommended_calories:"",
            remarks:"",
            isActive:false,
            nameerror:"",
            minimum_ageerror:"",
            maximum_ageerror:"",
            recommended_calorieserror:"",
            remarkerror:"",
            
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                name: this.props.user.name,
                minimum_age: this.props.user.minimum_age,
                maximum_age: this.props.user.maximum_age,
                recommended_calories: this.props.user.recommended_calories,
                remarks: this.props.user.remarks,
                isActive : this.props.user.isActive

            })
        }
       
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { name, minimum_age, maximum_age, recommended_calories, remarks, status } = this.state
        if(name.length < 1){
            error["name"] = "name is required"
        }else if(!isNameValid(name)){
            error["name"] = "Name only contain letters and Space"
        }
        if(minimum_age.length < 1){
            error["minimum_age"] = "minimum_age is required"
        }
        if(maximum_age.length < 1){
            error["maximum_age"] = "maximum_age is required"
        }
        if(recommended_calories.length < 1){
            error["recommended_calories"] = "recommended_calories is required"
        }
        if(remarks.length < 1){
            error["remark"] = "remark is required"
        }
       
        
        if(error["name"] || error["remark"] ){
            this.setState({
                nameerror: error["name"],
                minimum_ageerror: error["minimum_age"],
                maximum_ageerror: error["maximum_age"],
                recommended_calorieserror: error["recommended_calories"],
                remarkerror: error["remark"],
                
            })
        }else{
            
            let body = {
                name: name,
                maximum_age: maximum_age,
                minimum_age: minimum_age,
                recommended_calories: recommended_calories,
                remarks: remarks,
                isActive: this.state.isActive,
                isDeleted: false
               
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    name:"",
                    minimum_age:"",
                    maximum_age:"",
                    recommended_calories:"",
                    remarks:"",
                    isActive:""
                })
            }        
        }
    }
    
    render() {
        const { isPublished } = this.state;
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="name">Enter name</label>
                                <input 
                                    type="text" 
                                    value={this.state.name} 
                                    onChange={(e) => this.setState({ name: e.target.value, nameerror:e.target.value.length > 0? null: this.state.nameerror })} />
                                {this.state.nameerror ? <small style={{color: "red"}}>*{this.state.nameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="minimum_age">Enter minimum_age</label>
                                <input 
                                    type="number" 
                                    value={this.state.minimum_age} 
                                    onChange={(e) => this.setState({ minimum_age: e.target.value, minimum_ageerror:e.target.value.length > 0? null: this.state.minimum_ageerror })} />
                                {this.state.minimum_ageerror ? <small style={{color: "red"}}>*{this.state.minimum_ageerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="maximum_age">Enter maximum_age</label>
                                <input 
                                    type="number" 
                                    value={this.state.maximum_age} 
                                    onChange={(e) => this.setState({ maximum_age: e.target.value, maximum_ageerror:e.target.value.length > 0? null: this.state.maximum_ageerror })} />
                                {this.state.maximum_ageerror ? <small style={{color: "red"}}>*{this.state.maximum_ageerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="recommended_calories">Enter recommended_calories</label>
                                <input 
                                    type="text" 
                                    value={this.state.recommended_calories} 
                                    onChange={(e) => this.setState({ recommended_calories: e.target.value, recommended_calorieserror:e.target.value.length > 0? null: this.state.recommended_calorieserror })} />
                                {this.state.recommended_calorieserror ? <small style={{color: "red"}}>*{this.state.recommended_calorieserror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input 
                                type="text" 
                                value={this.state.remarks} 
                                onChange={(e) => this.setState({ remarks: e.target.value, remarkerror:e.target.value.length > 0? null: this.state.remarkerror })} />
                                {this.state.remarkerror ? <small style={{color: "red"}}>*{this.state.remarkerror}</small> : null}
                            </div>

                            <div className="input_box">
                                    <label htmlFor="isActive" className="form-label">isActive</label>
                                    <select
                                        onChange={(e) => this.setState({ isActive: e.target.value })}
                                        className="form-control" id="isActive"
                                        value={this.state.isActive}>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </div>
                            
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
