import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateComboMenu.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             username: "",
             main_item: "",
             first_side_item: "",
             second_side_item:"",
             portion_type:"",
             meal_type:"",
             status:"",
             nameerror: "",
             statuserror:"",
             mainitemerror: "",
             firstsideitemerror: "",
             secondsideitemerror:"",
             portiontypeerror:"",
             mealtypeerror:"",
            
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                username: this.props.user.name,
               main_item: this.props.user.main_item,
               status: this.props.user.status,
                first_side_item: this.props.user.first_side_item,
                second_side_item: this.props.user.second_side_item,
                portion_type: this.props.user.portion_type,
                meal_type: this.props.user.meal_type
               
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { username,main_item, first_side_item, second_side_item,portion_type,meal_type,status} = this.state
        if(username.length < 1){
            error["name"] = "Name is required"
        }else if(!isNameValid(username)){
            error["name"] = "Name only contain letters and Space"
        }
        if(status.length < 1){
            error["status"] = "status is required"
        }
        if(main_item.length < 1){
            error["main_item"] = "Main item is required"
        }
        if(first_side_item.length < 1){
            error["first_side_item"] = "first_side_item Number is required"
        }
        if(second_side_item.length < 1){
            error["second_side_item"] = "second_side_item Number is required"
        }
        if(portion_type.length < 1){
            error["portion_type"] = "portion_type Number is required"
        }
        if(meal_type.length < 1){
            error["meal_type"] = "meal_type Number is required"
        }
        if(error["name"] || error["main_item"]|| error["first_side_item"] || error["second_side_item"] ||
           error["portion_type"] || error["meal_type"]  ){
            this.setState({
                nameerror: error["name"],
                statuserror: error["status"],
                mainitemerror: error["main_item"],
                firstsideitemerror: error["first_side_item"],
                secondsideitemerror: error["first_side_item"],
                portiontypeerror: error["portion_type"],
                mealtypeerror: error["meal_type"]
               
                
            })
        }else{
            let temp = this.state.username.trim().split(" ")
            let tempName = []
            for(let i=0; i < temp.length; i++){
                if(temp[i] !== ''){
                    tempName.push(temp[i])
                }
            }
            let username = tempName.join(" ")
            let body = {
                name: username,
                status: status,
                main_item:main_item,
                first_side_item: first_side_item,
                second_side_item: second_side_item,
                portion_type: portion_type,
                meal_type: meal_type,
                isDeleted: false

               
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    username: "",
                    status:"",
                    main_item: "",
                    first_side_item: "",
                    second_side_item:"",
                    portion_type:"",
                    meal_type:"",
                   
               })
            }        
        }
    }
    
    render() {
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="name">Enter Name</label>
                                <input type="text" value={this.state.username} onChange={(e) => this.setState({ username: e.target.value, nameerror:e.target.value.length > 0? null: this.state.nameerror })} />
                                {this.state.nameerror ? <small style={{color: "red"}}>*{this.state.nameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="main_item">Enter main_item</label>
                                <input type="text" value={this.state.main_item} onChange={(e) => this.setState({ main_item: e.target.value, mainitemerror:e.target.value.length > 0? null: this.state.mainitemerror })} />
                                {this.state.mainitemerror ? <small style={{color: "red"}}>*{this.state.mainitemerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="first_side_item">Enter first_side_item </label>
                                <input type="text" value={this.state.first_side_item} onChange={(e) => this.setState({ first_side_item: e.target.value, firstsideitemerror:e.target.value.length > 0? null: this.state.firstsideitemerror })} />
                                {this.state.firstsideitemerror ? <small style={{color: "red"}}>*{this.state.firstsideitemerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="second_side_item">Enter second_side_item </label>
                                <input type="text" value={this.state.second_side_item} onChange={(e) => this.setState({ second_side_item: e.target.value, secondsideitemerror:e.target.value.length > 0? null: this.state.secondsideitemerror })} />
                                {this.state.secondsideitemerror ? <small style={{color: "red"}}>*{this.state.secondsideitemerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="portion_type">Enter portion_type </label>
                                <input type="text" value={this.state.portion_type} onChange={(e) => this.setState({ portion_type: e.target.value, portiontypeerror:e.target.value.length > 0? null: this.state.portiontypeerror })} />
                                {this.state.portiontypeerror ? <small style={{color: "red"}}>*{this.state.portiontypeerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="meal_type">Enter meal_type </label>
                                <input type="text" value={this.state.meal_type} onChange={(e) => this.setState({ meal_type: e.target.value, mealtypeerror:e.target.value.length > 0? null: this.state.mealtypeerror })} />
                                {this.state.mealtypeerror ? <small style={{color: "red"}}>*{this.state.mealtypeerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="status">Enter status</label>
                                <input type="number" value={this.state.status} onChange={(e) => this.setState({ status: e.target.value, statuserror:e.target.value.length > 0? null: this.state.statuserror })} />
                                {this.state.statuserror ? <small style={{color: "red"}}>*{this.state.statuserror}</small> : null}
                            </div>
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
