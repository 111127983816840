import React from 'react';

function ListTableHeader(props) {
    return (
        <tr style={{fontSize:"15px"}}>
            <th style={{minWidth: "120px"}}>Name</th>
            <th style={{minWidth: "180px"}}>Address_1</th>
            <th style={{minWidth: "180px"}}>Address_2</th>
            <th style={{minWidth: "160px"}}>Area_Info</th>
            <th style={{minWidth: "160px"}}>Sub_Area</th>
            <th style={{minWidth: "120px"}}>City</th>
            <th style={{minWidth: "120px"}}>Pin_Code</th>
            <th style={{minWidth: "150px"}}>State</th>
            <th style={{minWidth: "150px"}}>Country</th>
            <th style={{minWidth: "150px"}}>Email_1</th>
            <th style={{minWidth: "150px"}}>Email_2</th>
            <th style={{minWidth: "150px"}}>Phone_1</th>
            <th style={{minWidth: "150px"}}>Phone_2</th>
            <th style={{minWidth: "150px"}}>Fax</th>
            <th style={{minWidth: "150px"}}>Short_Name</th>
            <th style={{minWidth: "150px"}}>Can Subscribe</th>
            <th style={{minWidth: "150px"}}>Can Cancel</th>
            <th style={{minWidth: "150px"}}>Turn off auto Order</th>
            <th style={{minWidth: "150px"}}>View_Credits</th>
            <th style={{minWidth: "150px"}}>Status</th>
            <th style={{minWidth: "150px"}}>Remarks</th>
            <th style={{minWidth: "150px"}}>Action</th>
        </tr>
    );
}

export default ListTableHeader;


