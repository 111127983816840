import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isNumber, isPhoneValid, isValidString } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ingredientmaster: {
                ingredient: "",
                alias1: "",
                alias2: "",
                alias3: "",
                calorie: "",
                protein: "",
                fats: "",
                iron: "",
                calcium: "",
                carbohydrates: "",
                fibre: "",
                sodium: "",
                measurement: "",
                nutrition_id: "",
                update_by: "",
                remarks: "",
                status: ""
            },
            errors: {}
        }
    }

    componentDidMount() {
        if (this.props.user._id) {
            this.setState({
                ingredientmaster: this.props.user
            })
        }
    }

    handleValidation = (ingredientmaster) => {
        const { ingredient, alias1, alias2, alias3, calorie, protein, fats, iron, calcium, carbohydrates, fibre, sodium, measurement, nutrition_id, update_by, remarks, status } = ingredientmaster
        let errors = {};
        let isValid = true;
        if (!isNameValid(ingredient)) {
            isValid = false;
            errors['ingredient'] = "Enter Valid ingredient"
        }
        if (!isNameValid(alias1)) {
            isValid = false;
            errors['alias1'] = "Enter Valid Alias"
        }
        if (!isNameValid(alias2)) {
            isValid = false;
            errors['alias2'] = "Enter Valid alias"
        }
        if (!isNameValid(alias3)) {
            isValid = false;
            errors['alias3'] = "Enter Valid alias"
        }
        if (!isValidString(calorie)) {
            isValid = false;
            errors['calorie'] = "Enter Valid calorie value"
        }
        if (!isValidString(protein)) {
            isValid = false;
            errors['protein'] = "Enter Valid protein value"
        }
        if (!isValidString(fats)) {
            isValid = false;
            errors['fats'] = "Enter Valid fats value"
        }
        if (!isValidString(iron)) {
            isValid = false;
            errors['iron'] = "Enter Valid iron value"
        }
        if (!isValidString(calcium)) {
            isValid = false;
            errors['calcium'] = "Enter Valid calcium value"
        }
        if (!isValidString(carbohydrates)) {
            isValid = false;
            errors['carbohydrates'] = "Enter Valid carbohydrates value"
        }
        if (!isValidString(fibre)) {
            isValid = false;
            errors['fibre'] = "Enter Valid fibre value"
        }
        if (!isValidString(sodium)) {
            isValid = false;
            errors['sodium'] = "Enter Valid sodium value"
        }
        if (!isValidString(measurement)) {
            isValid = false;
            errors['measurement'] = "Enter Valid measurement value"
        }
        if (!isValidString(nutrition_id)) {
            isValid = false;
            errors['nutrition_id'] = "Enter Valid nutrition ID value"
        }
        if (!isNameValid(update_by)) {
            isValid = false;
            errors['update_by'] = "Enter Valid Name"
        }
        if (!isNameValid(remarks)) {
            isValid = false;
            errors['remarks'] = "Enter Valid Remark"
        }
        if (!isNumber(status)) {
            isValid = false;
            errors['status'] = "Enter Valid Number"
        }
        return { isValid, errors }
    }

    handleOnChange = (keyName, KeyValue) => {
        const { ingredientmaster } = this.state;
        ingredientmaster[keyName] = KeyValue;
        this.setState({
            ingredientmaster
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const ingredientmaster = this.state.ingredientmaster
        const { isValid, errors } = this.handleValidation(ingredientmaster)
        // console.log({ isValid, errors })
        const { ingredient, alias1, alias2, alias3, calorie, protein, fats, iron, calcium, carbohydrates, fibre, sodium, measurement, nutrition_id, update_by, remarks, status } = this.state.ingredientmaster

        if (isValid) {
            let body = {
                ingredient,
                alias1,
                alias2,
                alias3,
                calorie,
                protein,
                fats,
                iron,
                calcium,
                carbohydrates,
                fibre,
                sodium,
                measurement,
                nutrition_id,
                update_by,
                remarks,
                status
            }
            console.log("request body", body)
            if (this.props.user._id) {
                this.props.updateUser(this.props.user._id, body)
            } else {
                this.props.addUser(body);
                this.setState({
                    ingredientmaster: {}
                })
            }
        } else {
            this.setState({ errors })
        }
    }

    render() {
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{this.props.user._id ? "Update" : "Add"}</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="ingredient">Enter ingredient</label>
                                <input type="text" value={this.state.ingredientmaster.ingredient} id="ingredient" onChange={(e) => this.handleOnChange("ingredient", e.target.value)} />
                                {this.state.errors.ingredient ? <small style={{ color: "red" }}>*{this.state.errors.ingredient}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="alias1">Enter alias1</label>
                                <input type="text" value={this.state.ingredientmaster.alias1} id="alias1" onChange={(e) => this.handleOnChange("alias1", e.target.value)} />
                                {this.state.errors.alias1 ? <small style={{ color: "red" }}>*{this.state.errors.alias1}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="alias2">Enter alias2</label>
                                <input type="text" value={this.state.ingredientmaster.alias2} id="alias2" onChange={(e) => this.handleOnChange("alias2", e.target.value)} />
                                {this.state.errors.alias2 ? <small style={{ color: "red" }}>*{this.state.errors.alias2}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="alias3">Enter alias3</label>
                                <input type="text" value={this.state.ingredientmaster.alias3} id="alias3" onChange={(e) => this.handleOnChange("alias3", e.target.value)} />
                                {this.state.errors.alias3 ? <small style={{ color: "red" }}>*{this.state.errors.alias3}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="calories">Enter calories</label>
                                <input type="text" value={this.state.ingredientmaster.calorie} id="calories" onChange={(e) => this.handleOnChange("calorie", e.target.value)} />
                                {this.state.errors.calorie ? <small style={{ color: "red" }}>*{this.state.errors.calorie}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="protein">Enter protein</label>
                                <input type="text" value={this.state.ingredientmaster.protein} id="protein" onChange={(e) => this.handleOnChange("protein", e.target.value)} />
                                {this.state.errors.protein ? <small style={{ color: "red" }}>*{this.state.errors.protein}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="fats">Enter fats</label>
                                <input type="text" value={this.state.ingredientmaster.fats} id="fats" onChange={(e) => this.handleOnChange("fats", e.target.value)} />
                                {this.state.errors.fats ? <small style={{ color: "red" }}>*{this.state.errors.fats}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="iron">Enter iron</label>
                                <input type="text" value={this.state.ingredientmaster.iron} id="iron" onChange={(e) => this.handleOnChange("iron", e.target.value)} />
                                {this.state.errors.iron ? <small style={{ color: "red" }}>*{this.state.errors.iron}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="calcium">Enter calcium</label>
                                <input type="text" value={this.state.ingredientmaster.calcium} id="calcium" onChange={(e) => this.handleOnChange("calcium", e.target.value)} />
                                {this.state.errors.calcium ? <small style={{ color: "red" }}>*{this.state.errors.calcium}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="carbohydrates">Enter carbohydrates</label>
                                <input type="text" value={this.state.ingredientmaster.carbohydrates} id="carbohydrates" onChange={(e) => this.handleOnChange("carbohydrates", e.target.value)} />
                                {this.state.errors.carbohydrates ? <small style={{ color: "red" }}>*{this.state.errors.carbohydrates}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="fibre">Enter fibre</label>
                                <input type="text" value={this.state.ingredientmaster.fibre} id="fibre" onChange={(e) => this.handleOnChange("fibre", e.target.value)} />
                                {this.state.errors.fibre ? <small style={{ color: "red" }}>*{this.state.errors.fibre}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="sodium">Enter sodium</label>
                                <input type="text" value={this.state.ingredientmaster.sodium} id="sodium" onChange={(e) => this.handleOnChange("sodium", e.target.value)} />
                                {this.state.errors.sodium ? <small style={{ color: "red" }}>*{this.state.errors.sodium}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="measurement">Enter measurement</label>
                                <input type="text" value={this.state.ingredientmaster.measurement} id="measurement" onChange={(e) => this.handleOnChange("measurement", e.target.value)} />
                                {this.state.errors.measurement ? <small style={{ color: "red" }}>*{this.state.errors.measurement}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="nutrition_id">Enter nutrition_id</label>
                                <input type="text" value={this.state.ingredientmaster.nutrition_id} id="nutrition_id" onChange={(e) => this.handleOnChange("nutrition_id", e.target.value)} />
                                {this.state.errors.nutrition_id ? <small style={{ color: "red" }}>*{this.state.errors.nutrition_id}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="update_by">Enter update_by</label>
                                <input type="text" value={this.state.ingredientmaster.update_by} id="update_by" onChange={(e) => this.handleOnChange("update_by", e.target.value)} />
                                {this.state.errors.update_by ? <small style={{ color: "red" }}>*{this.state.errors.update_by}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input type="text" value={this.state.ingredientmaster.remarks} id="remarks" onChange={(e) => this.handleOnChange("remarks", e.target.value)} />
                                {this.state.errors.remarks ? <small style={{ color: "red" }}>*{this.state.errors.remarks}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="status">Enter status</label>
                                <input type="number" min="0" value={this.state.ingredientmaster.status} id="status" onChange={(e) => this.handleOnChange("status", e.target.value)} />
                                {this.state.errors.status ? <small style={{ color: "red" }}>*{this.state.errors.status}</small> : null}
                            </div>
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{this.props.user._id ? "Update" : "Add"}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
