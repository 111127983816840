import React, { Component } from 'react'
import CloseButton from '../../../../components/CloseButton/CloseButton'


export class RightShell extends Component {
    render() {
        return (
            <div className="shell" style={{height: "100vh", width: this.props.showShell ? "60vw" : "0px", position: "fixed", right: "0", top: "0px", backgroundColor: "rgb(250,250,250)", zIndex: "1000", transition: "0.7s"}}>
                <div className="border-bottom d-flex align-items-center justify-content-end pr-3" style={{height: "70px"}}>
                    <CloseButton addClick={this.props.handleShowShell} />
                </div>
                <div className="container-fluid" style={{height: "100%"}}>
                    <div className="row" style={{height: "100%"}}>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default RightShell
