import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';
import moment from 'moment';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            order_type_id:"",
            menu_type:"",
            main:"",
            side1:"",
            side2:"",
            combo:"",
            actual:"",
            scheduled_date: null,
            menutypeerror:"",
            mainerror:"",
            side1error:"",
            side2error:"",
            comboerror:"",
            actualerror:"",
            scheduleddateerror:"",
            
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                menu_type: this.props.user.menu_type,
                main: this.props.user.main,
                side1 : this.props.user.side1,
                side2 : this.props.user.side2,
                combo: this.props.user.combo,
                actual: this.props.user.actual,
                scheduled_date: this.props.user.scheduled_date,
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { menu_type, main, side1, side2, combo, actual, scheduled_date } = this.state
        if(menu_type.length < 1){
            error["menu_type"] = "Menu Type is required"
        }
        if(main.length < 1){
            error["main"] = "main is required"
        }
        if(side1.length < 1){
            error["side1"] = "side1 is required"
        }
        if(side2.length < 1){
            error["side2"] = "side2 is required"
        }
        if(combo.length < 1){
            error["combo"] = "combo is required"
        }
        if(actual.length < 1){
            error["actual"] = "actual is required"
        }
        if(!validator.isDate(scheduled_date)){
            error["scheduled_date"] = "Please enter valid date"
        }
       
        if(error["menu_type"] || error["main"] || error["side1"] || error["side2"] || error["combo"] || error["actual"] || error["scheduled_date"] ){
            this.setState({
                menutypeerror: error["menu_type"],
                mainerror: error["main"],
                side1error: error["side1"],
                side2error: error["side2"],
                comboerror: error["combo"],
                actualerror: error["actual"],
                scheduleddateerror: error["scheduled_date"],
                
            })
        }else{
           
            let body = {
                menu_type: menu_type,
                main: main,
                side1: side1,
                side2: side2,
                combo: combo,
                actual: actual,
                scheduled_date: scheduled_date,
                isDeleted: false
               
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    menu_type:"",
                    main:"",
                    side1:"",
                    side2:"",
                    combo:"",
                    actual:"",
                    scheduled_date:"",
                    
                })
            }        
        }
    }
    
    render() {
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="menu_type">Enter menu_type</label>
                                <input 
                                    type="text" 
                                    value={this.state.menu_type} 
                                    onChange={(e) => this.setState({ menu_type: e.target.value, menutypeerror:e.target.value.length > 0? null: this.state.menutypeerror })} />
                                {this.state.menutypeerror ? <small style={{color: "red"}}>*{this.state.menutypeerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="main">Enter main</label>
                                <input 
                                    type="text" 
                                    value={this.state.main} 
                                    onChange={(e) => this.setState({ main: e.target.value, mainerror:e.target.value.length > 0? null: this.state.mainerror })} />
                                {this.state.mainerror ? <small style={{color: "red"}}>*{this.state.mainerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="side1">Enter side1</label>
                                <input 
                                    type="text" 
                                    value={this.state.side1} 
                                    onChange={(e) => this.setState({ side1: e.target.value, side1error:e.target.value.length > 0? null: this.state.side1error })} />
                                {this.state.side1error ? <small style={{color: "red"}}>*{this.state.side1error}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="side2">Enter side2</label>
                                <input 
                                    type="text" 
                                    value={this.state.side2} 
                                    onChange={(e) => this.setState({ side2: e.target.value, side2error:e.target.value.length > 0? null: this.state.side2error })} />
                                {this.state.side2error ? <small style={{color: "red"}}>*{this.state.side2error}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="combo">Enter combo</label>
                                <input 
                                    type="text" 
                                    value={this.state.combo} 
                                    onChange={(e) => this.setState({ combo: e.target.value, comboerror:e.target.value.length > 0? null: this.state.comboerror })} />
                                {this.state.comboerror ? <small style={{color: "red"}}>*{this.state.comboerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="actual">Enter actual</label>
                                <input 
                                    type="number" 
                                    value={this.state.actual} 
                                    onChange={(e) => this.setState({ actual: e.target.value, actualerror:e.target.value.length > 0? null: this.state.actualerror })} />
                                {this.state.actualerror ? <small style={{color: "red"}}>*{this.state.actualerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="scheduled_date">Enter scheduled_date</label>
                                {/* <DatePicker selected={this.state.scheduled_date} value={this.state.scheduled_date} onChange={(e) => this.setState({ scheduled_date: e.target.value })}/> */}
                                <input type="date" min={moment.utc(Date.now()).local().format('YYYY-MM-DD')} value={moment.utc(this.state.scheduled_date).local().format('YYYY-MM-DD')} id="scheduled_date" onChange={(e) => this.setState({ scheduled_date: e.target.value })} />
                                {this.state.scheduleddateerror ? <small style={{color: "red"}}>*{this.state.scheduleddateerror}</small> : null}
                            </div>
                            
                            
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
