import React from 'react';

function ListTableHeader(props) {
    return (
        <tr style={{fontSize:"15px"}}>
            <th style={{minWidth: "150px"}}>Allergy Name</th>
            <th style={{minWidth: "150px"}}>Status</th>
            <th style={{minWidth: "120px"}}>remark</th>
            <th style={{minWidth: "150px"}}>Action</th>
        </tr>
    );
}

export default ListTableHeader;


