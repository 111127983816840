import { Avatar } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import callApi from '../../../util/apiCaller';
import "./header.css";
import image from '../../../assets/images/small/MBLOGO.png';
import mimage from '../../../assets/images/small/fav.png';
import SearchPage from './SearchPage';


class SubHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        user: {}
    };

}
  handleResponse = (response) => {
      return response.data
  }


//get the user with a particular id


    componentDidMount() {
      if(window.localStorage.getItem("user")){
          // console.log("user",window.localStorage.getItem("user"))
          const user = JSON.parse(window.localStorage.getItem("user"))
          // console.log("user",user)
          callApi(`auth/profile/${user.data._id}`, 'get')
          .then((res) => {
              if(res.user){
                  console.log(res.user)
                  this.setState((prevState) => {
                      return {
                          ...prevState,
                          user: res.user
                      }
                  })
              }
          })
          .catch((error) => console.log(error.message))
      }else{
          // alert("No data found")
      }
          
    }
    handleSidebar = () => {
      const sidebar = document.querySelector('#mobile_sidebar')
      if(sidebar.style.display === "block"){
        sidebar.style.display = "none"

      }
      else {
        sidebar.style.display = "block"
      }
    }
    render() {

      const { user } = this.state;
      
        return (
          <div>
             
              <header id="page-topbar" >
          <div className="navbar-header">
          <div  className="d-flex">
          
            <div className="mobile_logo" style={{background:" #252b3b",width:this.props.headerShow ? "240px":"70px",alignItems: "center",display:"flex"}}>
           <Link className="logo_size" style={{width:"181px"}}> 
           {this.props.headerShow ? <img  src={image} alt="logo" height={60} className="desktop_logo"/> :   <img src={mimage} alt="logo" height={60} className="mobile_logo"/>}
            
          {this.props.headerShow ? <img src={mimage} alt="logo" height={60} className="mobile_logo_res"/> : null } 
           
            
            </Link> 
            
         
              {/* <SearchPage/> */}
             
            </div>
            <div style={{alignItems: "center",display:"flex"}}>
            <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn" onClick={this.props.handletoggle}>
              <i className="ri-menu-2-line align-middle" style={{fontSize:"25px"}}></i>
              </button>
              <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect mobile_view" id="mobile_lg" onClick={() => this.handleSidebar()}>
              <i className="ri-menu-2-line align-middle" style={{fontSize:"25px"}}></i>
              </button>
            </div>
            
            </div>
            <div className="d-flex">
           <div className="dropdown d-inline-block user-dropdown" style={{marginTop:"10px"}}>
          <button type="button" className="btn header-item waves-effect d-flex align-items-center" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
           
            <Avatar />
            <span className="d-none d-xl-inline-block ml-2"style={{ color: "#000000",cursor:"pointer",fontWeight:"500"  }}>{user.name}</span>
            <i className="fa fa-angle-down d-none d-xl-inline-block" style={{ color: "#e9ecef",cursor:"pointer",fontWeight:"500",marginLeft:"10px" }}/>
          </button>
          <div className="dropdown-menu dropdown-menu-right" style={{}}>
            {/* item*/}
            <Link to="/profile" className="dropdown-item" role="button"><i className="ri-user-line align-middle mr-1" />Profile</Link>
         
            <a className="dropdown-item text-danger" role="button" onClick={() => this.props.logoutUser()}><i className="ri-shut-down-line align-middle mr-1 text-danger" /> Logout</a>
          </div>
          
        </div>
      </div>
          </div>
        </header>
       
        </div>
        );
    }
}

export default SubHeader;


