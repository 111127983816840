import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateActivityCenter.css"
import { LensOutlined } from '@material-ui/icons';

export class AddActivityCenter extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            name: "",
            address_1: "",
            address_2: "",
            sub_area:"",
            area_info: "",
            city: "",
            pin_code: "",
            state: "",
            country: "",
            email_1: "",
            email_2: "",
            phone_1: "",
            phone_2: "",
            fax: "",
            short_name: "",
            can_subscribe: "",
            can_cancel: '',
            turn_off_auto_order: "",
            view_credits: "",
            v1_pricing_id: "",
            status: "",
            remarks: "",
             nameerror: "",
             addresserror:"",
             area_infoerror:"",
             sub_areaerror:"",
             cityerror:"",
             pincodeerror:"",
             stateerror:"",
             countryerror:"",
             emailerror:"",
             phoneerror: "",
             faxerror:"",
             shortnameerror:"",
             can_subscribeerror:"",
             can_cancelerror:"",
             turn_off_auto_ordererror:"",
             viewcrediterror:"",
             v1_pricing_iderror:"",
             statuserror:"",
             remarkserror:""
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                name: this.props.user.name,
                address_1: this.props.user.address_1,
                address_2: this.props.user.address_2,
                area_info: this.props.user.area_info,
                sub_area: this.props.user.sub_area,
                city: this.props.user.city,
                pin_code: this.props.user.pin_code,
                state: this.props.user.state,
                country: this.props.user.country,
                email_1: this.props.user.email_1,
                email_2: this.props.user.email_2,
                phone_1: this.props.user.phone_1,
                phone_2: this.props.user.phone_2,
                fax: this.props.user.fax,
                short_name: this.props.user.short_name,
                can_subscribe: this.props.user.can_subscribe,
                can_cancel: this.props.user.can_cancel,
                turn_off_auto_order: this.props.user.turn_off_auto_order,
                view_credits: this.props.user.view_credits,
                status: this.props.user.status,
                remarks: this.props.user.remarks,

            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { name,address_1,address_2,area_info,sub_area,city,pin_code,state,country,
               email_1,email_2, phone_1,phone_2,fax,short_name,can_subscribe,can_cancel,turn_off_auto_order,view_credits,status,remarks } = this.state
        if(name.length < 1){
            error["name"] = "Name is required"
        }else if(!isNameValid(name)){
            error["name"] = "Name only contain letters and Space"
        }
    
        if(address_1.length < 1){
            error["address"] = "Address is required"
        }
        if(address_2.length < 1){
            error["address"] = "Address is required"
        }
        if(area_info.length < 1){
            error["area_info"] = "Area info is required"
        }
        if(sub_area.length < 1){
            error["sub_area"] = "Sub Area is required"
        }
        if(city.length < 1){
            error["city"] = "City is required"
        }
        if(pin_code.length < 1){
            error["pin_code"] = "Pin code is required"
        }
        if(state.length < 1){
            error["state"] = "State is required"
        }
        if(country.length < 1){
            error["country"] = "Country is required"
        }
        if(email_1.length < 1){
            error["email"] = "Email is required"
        }else if(!isEmailValid(email_1)){
            error["email"] = "Email is not Valid"
        }
        if(email_2.length < 1){
            error["email"] = "Email is required"
        }else if(!isEmailValid(email_2)){
            error["email"] = "Email is not Valid"
        }
        if(phone_1.length < 1){
            error["phone"] = "Phone Number is required"
        }else if(!isPhoneValid(phone_1)){
            error["phone"] = "Phone Number is not Valid"
        }
        if(phone_2.length < 1){
            error["phone"] = "Phone Number is required"
        }else if(!isPhoneValid(phone_2)){
            error["phone"] = "Phone Number is not Valid"
        }
        if(fax.length < 1){
            error["fax"] = "Fax is required"
        }
        if(short_name.length < 1){
            error["short_name"] = "Short Name is required"
        }
        if(can_subscribe.length < 1){
            error["can_subscribe"] = "Can Subscribe is required"
        }
        if(can_cancel.length < 1){
            error["can_cancel"] = "Can cancel is required"
        }
        if(turn_off_auto_order.length < 1){
            error["turn_off_auto_order"] = "Turn off auto order is required"
        }
        if(view_credits.length < 1){
            error["view_credits"] = "Creadits is required"
        }
        if(status.length < 1){
            error["status"] = "Status is required"
        }
        if(remarks.length < 1){
            error["remarks"] = "Remarks is required"
        }
        if( error["name"] ||error["address"] || error["area_info"] || error["sub_area"] || error["city"] || error["pin_code"] || error["state"] || 
            error["country"] || error["email"] || error["phone"] ||  error["fax"] || error["view_credits"] || error["short_name"] || error["can_subscribe"] ||
            error["can_cancel"] || error["turn_off_auto_order"] || error["status"] || error["remarks"]){
            this.setState({
                nameerror: error["name"],
                addresserror: error["address"],
                area_infoerror: error["area_info"],
                sub_areaerror: error["sub_area"],
                cityerror: error["city"],
                pincodeerror: error["pin_code"],
                stateerror: error["state"],
                countryerror: error["country"],
                emailerror: error["email"],
                phoneerror: error["phone"],
                faxerror: error["fax"],
                shortnameerror: error["short_name"],
                can_subscribeerror: error["can_subscribe"],
                can_cancelerror: error["can_cancel"],
                turn_off_auto_ordererror: error["turn_off_auto_order"],
                viewcrediterror: error["view_credits"],
                statuserror: error["status"],
                remarkserror: error["remarks"]
                
            })
        }else{
            let body = {
                name: name,
                address_1: address_1,
                address_2: address_2,
                area_info: area_info,
                sub_area: sub_area,
                city: city,
                pin_code: pin_code,
                state: state,
                country: country,
                email_1: email_1,
                email_2: email_2,
                phone_1: phone_1,
                phone_2: phone_2,
                fax: fax,
                short_name: short_name,
                can_subscribe: can_subscribe,
                can_cancel: can_cancel,
                turn_off_auto_order: turn_off_auto_order,
                view_credits: view_credits,
                status: status,
                remarks: remarks
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    name: "",
                    address_1:"",
                    address_2:"",
                    area_info:"",
                    sub_area:"",
                    city:"",
                    pin_code:"",
                    state:"",
                    country:"",
                    email_1: "",
                    email_2:"",
                    phone_1: "",
                    phone_2:"",
                    fax:"",
                    short_name:"",
                    can_subscribe:"",
                    can_cancel:"",
                    turn_off_auto_order:"",
                    view_credits:"",
                    status:"",
                    remarks:""
                })
            }        
        }
    }
    
    render() {
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="name">Enter Name</label>
                                <input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value, nameerror:e.target.value.length > 0? null: this.state.nameerror })} />
                                {this.state.nameerror ? <small style={{color: "red"}}>*{this.state.nameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="address_1">Enter Address_1</label>
                                <input type="textarea" value={this.state.address_1} onChange={(e) => this.setState({ address_1: e.target.value, addresserror:e.target.value.length > 0? null: this.state.addresserror })} />
                                {this.state.addresserror ? <small style={{color: "red"}}>*{this.state.addresserror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="address_2">Enter Address_2</label>
                                <input type="textarea" value={this.state.address_2} onChange={(e) => this.setState({ address_2: e.target.value, addresserror:e.target.value.length > 0? null: this.state.addresserror })} />
                                {this.state.addresserror ? <small style={{color: "red"}}>*{this.state.addresserror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="area_info">Enter Area_Info</label>
                                <input type="text" value={this.state.area_info} onChange={(e) => this.setState({ area_info: e.target.value, area_infoerror:e.target.value.length > 0? null: this.state.area_infoerror })} />
                                {this.state.area_infoerror ? <small style={{color: "red"}}>*{this.state.area_infoerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="sub_area">Enter sub_area</label>
                                <input type="text" value={this.state.sub_area} onChange={(e) => this.setState({ sub_area: e.target.value, sub_areaerror:e.target.value.length > 0? null: this.state.sub_areaerror }) } />
                                {this.state.sub_areaerror ? <small style={{color: "red"}}>*{this.state.sub_areaerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="city">Enter City</label>
                                <input type="text" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value, cityerror:e.target.value.length > 0? null: this.state.cityerror })} />
                                {this.state.city ? <small style={{color: "red"}}>*{this.state.city}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="pin_code">Enter Pin_Code</label>
                                <input type="text" value={this.state.pin_code} onChange={(e) => this.setState({ pin_code: e.target.value, pincodeerror:e.target.value.length > 0? null: this.state.pincodeerror })} />
                                {this.state.pincodeerror ? <small style={{color: "red"}}>*{this.state.pincodeerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="state">Enter state</label>
                                <input type="text" value={this.state.state} onChange={(e) => this.setState({ state: e.target.value, stateerror:e.target.value.length > 0? null: this.state.stateerror })} />
                                {this.state.stateerror ? <small style={{color: "red"}}>*{this.state.stateerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="country">Enter Country</label>
                                <input type="text" value={this.state.country} onChange={(e) => this.setState({ country: e.target.value, countryerror:e.target.value.length > 0? null: this.state.countryerror })} />
                                {this.state.countryerror ? <small style={{color: "red"}}>*{this.state.countryerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="email_1">Enter Email_1</label>
                                <input type="email" value={this.state.email_1} onChange={(e) => this.setState({ email_1: e.target.value, emailerror:e.target.value.length > 0? null: this.state.emailerror })} />
                                {this.state.emailerror ? <small style={{color: "red"}}>*{this.state.emailerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="email_2">Enter Email_2</label>
                                <input type="email" value={this.state.email_2} onChange={(e) => this.setState({ email_2: e.target.value, emailerror:e.target.value.length > 0? null: this.state.emailerror })} />
                                {this.state.emailerror ? <small style={{color: "red"}}>*{this.state.emailerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="phone_1">Enter Phone_1 Number</label>
                                <input type="text" value={this.state.phone_1} onChange={(e) => this.setState({ phone_1: e.target.value, phoneerror:e.target.value.length > 0? null: this.state.phoneerror })} />
                                {this.state.phoneerror ? <small style={{color: "red"}}>*{this.state.phoneerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="phone_2">Enter Phone_2 Number</label>
                                <input type="text" value={this.state.phone_2} onChange={(e) => this.setState({ phone_2: e.target.value, phoneerror:e.target.value.length > 0? null: this.state.phoneerror })} />
                                {this.state.phoneerror ? <small style={{color: "red"}}>*{this.state.phoneerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="fax">Enter Fax</label>
                                <input type="text" value={this.state.fax} onChange={(e) => this.setState({ fax: e.target.value, faxerror:e.target.value.length > 0? null: this.state.faxerror })} />
                                {this.state.faxerror ? <small style={{color: "red"}}>*{this.state.faxerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="short_name">Enter Short_Name</label>
                                <input type="text" value={this.state.short_name} onChange={(e) => this.setState({ short_name: e.target.value, shortnameerror:e.target.value.length > 0? null: this.state.shortnameerror })} />
                                {this.state.shortnameerror ? <small style={{color: "red"}}>*{this.state.shortnameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="can_subscribe">Enter can_subscribe</label>
                                <input type="number" value={this.state.can_subscribe} onChange={(e) => this.setState({ can_subscribe: e.target.value, can_subscribeerror:e.target.value.length > 0? null: this.state.can_subscribeerror })} />
                                {this.state.can_subscribeerror ? <small style={{color: "red"}}>*{this.state.can_subscribeerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="can_cancel">Enter can_cancel</label>
                                <input type="number" value={this.state.can_cancel} onChange={(e) => this.setState({ can_cancel: e.target.value, can_cancelerror:e.target.value.length > 0? null: this.state.can_cancelerror })} />
                                {this.state.can_cancelerror ? <small style={{color: "red"}}>*{this.state.can_cancelerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="turn_off_auto_order">Enter turn_off_auto_order</label>
                                <input type="number" value={this.state.turn_off_auto_order} onChange={(e) => this.setState({ turn_off_auto_order: e.target.value, turn_off_auto_ordererror:e.target.value.length > 0? null: this.state.turn_off_auto_ordererror })} />
                                {this.state.turn_off_auto_ordererror ? <small style={{color: "red"}}>*{this.state.turn_off_auto_ordererror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="view_credits">Enter View_Credits</label>
                                <input type="number" value={this.state.view_credits} onChange={(e) => this.setState({ view_credits: e.target.value, viewcrediterror:e.target.value.length > 0? null: this.state.viewcrediterror })} />
                                {this.state.viewcrediterror ? <small style={{color: "red"}}>*{this.state.viewcrediterror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="status">Enter status</label>
                                <input type="number" value={this.state.status} onChange={(e) => this.setState({ status: e.target.value, statuserror:e.target.value.length > 0? null: this.state.statuserror })} />
                                {this.state.statuserror ? <small style={{color: "red"}}>*{this.state.statuserror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input type="text" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value, remarkserror:e.target.value.length > 0? null: this.state.remarkserror })} />
                                {this.state.remarkserror ? <small style={{color: "red"}}>*{this.state.remarkserror}</small> : null}
                            </div>
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddActivityCenter;
