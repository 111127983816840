import React, {Component} from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from "@material-ui/icons/Visibility"
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class ListTableRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    };


    render() {
        return (
            <tr className="">
                <td style={{minWidth: "150px", fontWeight: "500"}}>{this.props.user.name}</td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.address_1}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.address_2}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.area_info}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.sub_area}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.city}</a>
                </td>
                <td style={{minWidth: "180px"}}>
                    <a>{this.props.user.pin_code}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.state}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.country}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.email_1}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.email_2}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.phone_1}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.phone_2}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.fax}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.short_name}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.can_subscribe}</a>
                </td> 
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.can_cancel}</a>
                </td> 
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.turn_off_auto_order}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.view_credits}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.status}</a>
                </td>
                <td style={{minWidth: "120px"}}>
                    <a>{this.props.user.remarks}</a>
                </td>
                <td style={{minWidth: "80px"}}>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          View More
                        </Tooltip>
                    }
                    >
                    <VisibilityIcon onClick={() => this.props.handleShowShell(this.props.user._id)} fontSize="small" style={{color: "#045404", marginRight: "15px", cursor: "pointer"}} />
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          Edit
                        </Tooltip>
                    }
                    >
                    <EditIcon onClick={() => this.props.displayModal(this.props.user._id)} fontSize="small" style={{color: "#3399ff", marginRight: "15px", cursor: "pointer"}} />
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                          Delete
                        </Tooltip>
                    }
                    >
                    <DeleteIcon onClick={() => this.props.deleteUser(this.props.user._id)} fontSize="small" style={{color: "#d81212", cursor: "pointer"}} />
                </OverlayTrigger>
                </td>
                
            </tr>
        );
    }
}

export default ListTableRow;


