import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            foodItemID:"",
            name:"",
            remarks:"",
            name_slug:"",
            status:"",
            isError:"",
            nameerror:"",
            remarkserror:"",
            statuserror:"",
        }
    }

    componentDidMount(){
        if(this.props.user._id){
            this.setState({
                name: this.props.user.name,
                remarks: this.props.user.remarks,
                status : this.props.user.status

            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const error = {}
        const { name, remarks,status } = this.state
        if(name.length < 1){
            error["name"] = "name is required"
        }else{
            error["name"] = ""
        }
        if(remarks.length < 1){
            error["remarks"] = "remarks is required"
        }
        if(status.length < 1){
            error["status"] = "status  is required"
        }
        if(error["name"] || error["remarks"] ||  error["status"] ){
            this.setState({
                nameerror: error["name"],
                remarkserror: error["remarks"],
                statuserror: error["status"]
            })
        }else{
            let body = {
                name: name,
                remarks: remarks,
                status: status,
                isDeleted: false
               
            }
            if(this.props.user._id){
                this.props.updateUser(this.props.user._id, body)
            }else{
                this.props.addUser(body);
                this.setState({
                    name:"",
                    remarks:"",
                    status:"",
                
                })
            }        
        }
    }
    
    render() {
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{ this.props.user._id ? "Update" : "Add" }</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="name">Enter name</label>
                                <input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value, nameerror:e.target.value.length > 0? null: this.state.nameerror })  } />
                                {this.state.nameerror ? <small style={{color: "red"}}>*{this.state.nameerror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remarks</label>
                                <input type="text" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value, remarkserror:e.target.value.length > 0? null: this.state.remarkserror })} />
                                {this.state.remarkserror ? <small style={{color: "red"}}>*{this.state.remarkserror}</small> : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="status">Enter status</label>
                                <input type="number" value={this.state.status} onChange={(e) => this.setState({ status: e.target.value, statuserror:e.target.value.length > 0? null: this.state.statuserror })} />
                                {this.state.statuserror ? <small style={{color: "red"}}>*{this.state.statuserror}</small> : null}
                            </div>
                            
                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{ this.props.user._id ? "Update" : "Add" }</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
