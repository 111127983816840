import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import ListTable from './components/ListTable';
import _ from 'lodash';
import callApi from '../../../util/apiCaller';
import AddAndUpdateUser from "./components/AddAndUpdate/AddAndUpdateUser"
import { Redirect } from 'react-router-dom';
import RightShell from './components/RightShell';
import Notifications, { notify } from 'react-notify-toast';

const MODEL_NAME = `ingredientmaster`;
const MODEL = 'ingredient_master'
const LIST = "Ingredient Master List"
const add = "Ingredient Master data is Added Successfully"
const server = "Something went wrong"
const update = "Ingredient Master data is Updated Successfully"
const deleted = "Ingredient Master data is Successfully deleted"
const refresh = "Data is refreshed"


class IngredientMasterListPage extends Component {
    constructor() {
        super();
        this.state = {
            isMounted: false,
            isLoading: false,
            isSaving: false,
            items: [],
            count: 0,
            isEditing: false,
            isRefreshing: false,
            isSearching: false,
            pageNum: 1,
            pageSize: 25,
            qtext: '',
            showModal: false,
            showShell: false,
            user: {}
        };
        this.debouncedSearch = _.debounce(this.handleSearch, 700);
    };

    componentDidMount() {
        let filters = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            qtext: this.state.qtext
        };
        this.setState({ isLoading: true });
        this.fetchListItems(filters, (err, res) => {
            if (err) {
                console.log(err);
            } else {
                // console.log(res)
                this.setState({
                    items: res.data[MODEL + 's'] ? res.data[MODEL + 's'] : [],
                    count: res.data.count ? res.data.count : 0,
                    isRefreshing: false,
                    isLoading: false,
                    isSearching: false
                }, () => console.log(this.state));
            }
        });
    };

    fetchListItems = (filters, next) => {
        callApi(`v2/${MODEL_NAME}/list`, 'POST', filters).then(res => {
            return next(null, res);
        }).catch(err => {
            return next(err);
        });
    };

    filterItems = (filters) => {
        this.setState({
            isRefreshing: true,
            pageNum: filters.pageNum ? filters.pageNum : this.state.pageNum,
            pageSize: filters.pageSize ? filters.pageSize : this.state.pageSize,
            qtext: filters.qtext
        });
        this.fetchListItems(filters, (err, res) => {
            if (err) {
                console.log(err);
                this.showMessage(`${server}`, "error")
            } else {
                this.setState({
                    items: res.data[MODEL + 's'] ? res.data[MODEL + 's'] : [],
                    count: res.data.count ? res.data.count : 0,
                    isRefreshing: false,
                    isLoading: false,
                    isSearching: false
                }, console.log(this.state.items));
                this.showMessage(`${refresh}`, "success")
            }
        });
    };

    addNewItem = (item) => {
        this.setState({ showModal: false })
        let body = {};
        body[MODEL] = item;
        callApi(`v2/${MODEL_NAME}/new`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                let newItem = res.data[MODEL];
                let items = this.state.items;
                items.unshift(newItem);
                this.setState({ items: items, count: this.state.count + 1 });
                this.showMessage(`${add}`, "success");
            } else {
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            console.error(err);
            this.showMessage(`Error on adding ${MODEL_NAME.toUpperCase()}`, "error");
        });
    };

    deleteItem = (id) => {
        const confirm = window.confirm("Do you want to delete the user?")
        if (confirm) {
            callApi(`v2/${MODEL_NAME}/${id}/remove`, 'POST', {}).then(res => {
                if (res && res.status === 'Success') {
                    let items = this.state.items.filter(item => {
                        if (item._id !== id) {
                            return item;
                        }
                    });
                    this.setState({ items: items, count: this.state.count - 1 });
                    this.showMessage(`${deleted}`, "success");
                } else {
                    this.showMessage(`${server}`, "error")
                }
            }).catch(err => {
                this.showMessage(`Error on deleting ${MODEL_NAME.toUpperCase()}`, "error");
            });
        }
    };

    updateItem = (id, item) => {
        let body = {};
        body[MODEL] = item;
        callApi(`v2/${MODEL_NAME}/${id}/update`, 'POST', body).then(res => {
            if (res && res.status === 'Success') {
                let newItem = res.data[MODEL];
                let items = this.state.items.map(item => {
                    return newItem._id === item._id ? newItem : item;
                });
                this.setState({ items: items });
                this.setState({ showModal: false, user: {} })
                this.showMessage(`${update}`, "success");
            } else {
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`, "error");
        });
    };


    //getUser
    getItem = (id, forWhich) => {
        callApi(`v2/${MODEL_NAME}/${id}`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                let newItem = res.data[MODEL];
                this.setState({ user: newItem }, () => console.log(this.state.user))
                if (forWhich === "modal") {
                    this.setState({ showModal: true })
                    document.body.style.overflow = "hidden";
                } else {
                    this.setState({ showShell: true })
                }
            } else {
                this.showMessage(`${server}`, "error")
            }
        }).catch(err => {
            this.showMessage(`Error on updating ${MODEL_NAME.toUpperCase()}`, "error");
        });
    };

    showMessage = (msg, msgType) => {
        document.body.style.overflow = "auto"
        if (msgType === "success") {
            notify.show(msg, msgType, 3000);
        }
        if (msgType === "error") {
            notify.show(msg, msgType, 3000);
        }
    };

    handleQueryChange = (event) => {
        this.setState({
            qtext: event
        }, () => {
            this.debouncedSearch();
        });
    };

    handleSearch = () => {
        let filters = {
            pageNum: 1,
            pageSize: this.state.pageSize,
            qtext: this.state.qtext
        };
        console.log(filters)
        this.filterItems(filters);
    };


    // show and hide modal for add user and update user
    displayModal = (id = "") => {
        if (id) {
            this.getItem(id, "modal")
        } else {
            this.setState({ showModal: true })
        }
        document.body.style.overflow = "hidden";
    }

    hideModal = () => {
        document.body.style.overflow = "auto";
        this.setState({ user: {} })
        this.setState({ showModal: false })
        console.log(this.state)
    }

    handleShowShell = (id = "") => {
        if (id) {
            this.getItem(id, "shell")
        } else {
            this.setState({ showShell: false, user: {} })
        }
    }


    render() {
        return (
            <>
                <Notifications />
                { this.props.isAuthenticated ?
                    <div className="main-content" style={{ marginLeft: this.props.showSidebar ? "240px" : "70px", marginTop: "90px" }}>
                        {this.state.showModal ? <AddAndUpdateUser hideModal={this.hideModal} updateUser={this.updateItem} addUser={this.addNewItem} user={this.state.user} /> : null}
                        {this.state.showShell ? <RightShell handleShowShell={this.handleShowShell} showShell={this.state.showShell} user={this.state.user} /> : null}
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">

                                                <Row className="clearfix">
                                                    <ListTable
                                                        list={this.state.items}
                                                        count={this.state.count}
                                                        page={this.state.pageNum}
                                                        size={this.state.pageSize}
                                                        qtext={this.state.qtext}
                                                        isLoading={this.state.isLoading}
                                                        isRefreshing={this.state.isRefreshing}
                                                        isSearching={this.state.isSearching}
                                                        name={LIST}
                                                        deleteUser={this.deleteItem}
                                                        updateUser={this.updateItem}
                                                        displayModal={this.displayModal}
                                                        handleQueryChange={this.handleQueryChange}
                                                        filters={this.filterItems}
                                                        handleSearch={this.handleSearch}
                                                        showShell={this.state.showShell}
                                                        handleShowShell={this.handleShowShell}
                                                    />
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : (
                        <>
                            {<Redirect to="/login" />}
                        </>
                    )}
            </>
        )
    }
}

export default IngredientMasterListPage;

