import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { isEmailValid, isNameValid, isPhoneValid, isValidString } from '../../../../../util/validation';
import "./AddAndUpdateUser.css"
import validator from 'validator';
import { LensOutlined } from '@material-ui/icons';

export class AddUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            foodcategory: {
                name: "",
                remarks: "",
                isActive: false,
                name_slug: ""
            },
            errors: {}
        }
    }

    componentDidMount() {
        if (this.props.user._id) {
            this.setState({
                foodcategory: this.props.user
            })
        }
    }

    handleValidation = (foodcategory) => {
        const { name, remarks, name_slug } = foodcategory
        let errors = {};
        let isValid = true;
        if (!isNameValid(name)) {
            isValid = false;
            errors['name'] = "Enter Valid Name"
        }
        if (!isNameValid(remarks)) {
            isValid = false;
            errors['remarks'] = "Enter Valid Remark"
        }
        if (!isValidString(name_slug)) {
            isValid = false;
            errors['name_slug'] = "Enter Valid Slug"
        }
        return { isValid, errors }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const foodcategory = this.state.foodcategory;
        const { isValid, errors } = this.handleValidation(foodcategory);
        const { name, name_slug, remarks, isActive } = this.state.foodcategory
        if (isValid) {
            let body = {
                name,
                name_slug,
                remarks,
                isActive,
            }
            if (this.props.user._id) {
                this.props.updateUser(this.props.user._id, body)
            } else {
                this.props.addUser(body);
                this.setState({
                    foodcategory: {},
                    errors: {}
                })
            }
        } else {
            this.setState({ errors })
        }
    }

    handleOnChange = (keyName, keyValue) => {
        let foodcategory = this.state.foodcategory;
        foodcategory[keyName] = keyValue;
        this.setState({
            foodcategory
        });
    }

    render() {
        return (
            <div className="user" onClick={() => this.props.hideModal()}>
                <div className="user_box" onClick={(e) => e.stopPropagation()}>
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="user_boxHeading">
                            <h2>{this.props.user._id ? "Update" : "Add"}</h2>
                            <CloseIcon onClick={() => this.props.hideModal()} />
                        </div>
                        <div className="user_boxInputs">
                            <div className="input_box">
                                <label htmlFor="name">Enter name</label>
                                <input type="text" value={this.state.foodcategory.name} onChange={(e) => this.handleOnChange("name", e.target.value)} />
                                {this.state.errors.name ? (
                                    <small style={{ color: 'red' }}>
                                        *{this.state.errors.name}
                                    </small>
                                ) : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="name">Enter name slug</label>
                                <input type="text" value={this.state.foodcategory.name_slug} onChange={(e) => this.handleOnChange("name_slug", e.target.value)} />
                                {this.state.errors.name_slug ? (
                                    <small style={{ color: 'red' }}>
                                        *{this.state.errors.name_slug}
                                    </small>
                                ) : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="remarks">Enter remark</label>
                                <input type="text" value={this.state.foodcategory.remarks} onChange={(e) => this.handleOnChange("remarks", e.target.value)} />
                                {this.state.errors.remarks ? (
                                    <small style={{ color: 'red' }}>
                                        *{this.state.errors.remarks}
                                    </small>
                                ) : null}
                            </div>
                            <div className="input_box">
                                <label htmlFor="isActive" className="form-label">isActive</label>
                                <select
                                    onChange={(e) => this.handleOnChange("isActive", e.target.value)}
                                    className="form-control" id="isActive"
                                    value={this.state.foodcategory.isActive}>
                                    <option value={true}>True</option>
                                    <option value={false} selected>False</option>
                                </select>
                            </div>


                            <div className="button_box">
                                <button onClick={() => this.props.hideModal()} className="cancel_button">Cancel</button>
                                <button type="submit" className="add_button">{this.props.user._id ? "Update" : "Add"}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddUser;
